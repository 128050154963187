import { useRaasMetrics } from 'pages/raas/hooks';
import { useParams } from 'react-router-dom';
import { Metric } from 'shared/components/metric';
import { Panel } from 'shared/types/protoc-gen/raas-metrics';
import { formatAbbreviatedNumber } from 'shared/utils/strings';

import { useDateRangeFilterOptions } from '../../hooks/useDateRangeFilterOptions';
import { DateFilterVariant } from '../../store';

export interface ITxPoolMetric {
  dateFilterVariant?: DateFilterVariant;
}

export const TxPoolMetric = ({ dateFilterVariant }: ITxPoolMetric) => {
  const { rollupId } = useParams();

  const { end, start, step, timeframe } = useDateRangeFilterOptions(
    dateFilterVariant ?? DateFilterVariant.OVERVIEW_METRICS_SECTION,
  );

  const { data: result, isPending } = useRaasMetrics({
    rollupId,
    panel: Panel.PANEL_OPSTACK_TX_POOL,
    timeframe,
    start,
    end,
    step,
  });

  return (
    <Metric
      chartType="line"
      data={result}
      description={
        'Transaction pool statistics, including the number of local, pending, and queued transactions over time'
      }
      hideLegend={false}
      hideTooltipKeys
      id={'tx-pool'}
      legendSpacing={'8px'}
      loading={isPending}
      title={'Tx pool'}
      yAxisProps={{
        tickFormatter: value => formatAbbreviatedNumber(value),
      }}
    />
  );
};
