import { Box, Typography } from '@mui/material';

interface IWithdrawalSubSectionHeader {
  title: string;
}

export const WithdrawalSubSectionHeader: React.FC<IWithdrawalSubSectionHeader> = ({ title }) => {
  return (
    <Box
      sx={{
        backgroundColor: '#f7f7f7',
        p: '16px 40px',
        borderBottom: theme => `1px solid ${theme.colors.functional.subject.border}`,
        borderTop: theme => `1px solid ${theme.colors.functional.subject.border}`,
      }}
    >
      <Typography sx={{ fontWeight: 500, fontSize: '16px', lineHeight: '24px' }}>
        {title}
      </Typography>
    </Box>
  );
};
