import {
  Box,
  IconButton,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ReactNode, useCallback } from 'react';
import { IoArrowBackSharp } from 'react-icons/io5';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconAaa } from 'shared/components/icons/IconAaa';
import { IconAltLayerFlat } from 'shared/components/icons/IconAltLayerFlat';
import IconDeploy from 'shared/components/icons/IconDeploy';
import { ITabNavigation, TabNavigation } from 'shared/components/Layout/TabNavigation';
import { colors } from 'theme/colors';
import { px } from 'theme/margins';

export enum PageBannerVariant {
  AVS = 'AVS',
  RAAS = 'RAAS',
  AAAS = 'AAAS',
  ADMIN = 'ADMIN',
}

const title = {
  [PageBannerVariant.AVS]: 'AVS Dashboard',
  [PageBannerVariant.RAAS]: 'Rollup Dashboard',
  [PageBannerVariant.AAAS]: 'Autonome Dashboard',
  [PageBannerVariant.ADMIN]: 'Admin Dashboard',
};

const _iconDeploy = (
  <IconDeploy
    sx={{
      width: { md: '379px', sm: '90px', xs: '90px' },
      height: { md: '220px', sm: '90px', xs: '90px' },
      color: theme => theme.colors.functional.text.primary,
    }}
  />
);

const icon = {
  [PageBannerVariant.AVS]: _iconDeploy,
  [PageBannerVariant.RAAS]: _iconDeploy,
  [PageBannerVariant.AAAS]: (
    <IconAaa
      sx={{
        height: { md: '220px', sm: '90px', xs: '90px' },
        width: { md: '220px', sm: '90px', xs: '90px' },
      }}
    />
  ),
  [PageBannerVariant.ADMIN]: (
    <IconAltLayerFlat
      fillColor={colors.functional.text.primary}
      sx={{
        width: { md: '379px', sm: '90px', xs: '90px' },
        height: { md: '220px', sm: '90px', xs: '90px' },
      }}
    />
  ),
};

const useBackground = (variant?: PageBannerVariant) => {
  const theme = useTheme();

  switch (variant) {
    case PageBannerVariant.AVS:
      return theme.colors.gradients.water;

    case PageBannerVariant.RAAS:
      return theme.colors.gradients.leaf;

    case PageBannerVariant.AAAS:
      return theme.colors.gradients.cream;

    case PageBannerVariant.ADMIN:
      return theme.colors.gradients.altLayer;

    default:
      return theme.colors.gradients.water;
  }
};

export interface IPageBanner {
  variant?: PageBannerVariant;
  title?: string;
  renderSubTitleContent?: ReactNode;
  loading?: boolean;
  onClickBack?: () => void;
  tabNavigationProps?: Partial<ITabNavigation>;
  startAdornment?: ReactNode;
}

export function PageBanner({
  loading,
  onClickBack,
  renderSubTitleContent: endAdornment,
  startAdornment,
  tabNavigationProps,
  title: _title,
  variant,
}: IPageBanner) {
  const theme = useTheme();

  const sm = useMediaQuery(theme.breakpoints.down('md'));

  const location = useLocation();
  const navigate = useNavigate();
  const background = useBackground(variant);
  const handleBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <>
      <Box sx={{ background }}>
        <Box margin="0 auto" pb={{ md: 5, xs: 5 }} pt={{ md: 5, xs: 5 }} px={px}>
          {startAdornment}

          <Stack alignItems="flex-end" flexDirection="row" justifyContent="space-between">
            <Stack alignItems="start">
              <Stack
                alignItems="center"
                direction="row"
                display="flex"
                justifyContent="space-between"
                spacing={{ md: 5, sm: 3, xs: 3 }}
              >
                {(location.pathname?.match(/\/(rollups|avs|aaa)\/[^/]+/) || onClickBack) && (
                  <IconButton
                    onClick={onClickBack ?? handleBack}
                    sx={{
                      height: { md: '56px', sm: '32px', xs: '32px' },
                      width: { md: '56px', sm: '32px', xs: '32px' },
                      '&,&:hover': {
                        background: theme.colors.functional.container.primary,
                        color: '#fff',
                      },
                    }}
                  >
                    <IoArrowBackSharp size={24} />
                  </IconButton>
                )}

                {loading ? (
                  <Skeleton sx={{ minWidth: '400px', minHeight: '80px' }} variant="rectangular" />
                ) : (
                  <Typography
                    data-testid="dashboard-title"
                    sx={{ color: theme => theme.colors.functional.text.primary }}
                    variant={!sm ? 'h1' : 'h5'}
                  >
                    {_title ?? (variant && title[variant])}
                  </Typography>
                )}
              </Stack>

              {endAdornment}
            </Stack>

            {(variant && icon[variant]) ?? _iconDeploy}
          </Stack>
        </Box>
      </Box>
      {(tabNavigationProps?.items ?? []).length > 0 && (
        <TabNavigation
          activeColor={tabNavigationProps?.activeColor || background}
          activeIndex={tabNavigationProps?.activeIndex}
          items={tabNavigationProps?.items}
        />
      )}
    </>
  );
}
