import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { bff } from 'shared/api';
import {
  FeeVault,
  feeVaultFromJSON,
  WithdrawalNetwork,
  WithdrawFeeStatusReply,
} from 'shared/types/protoc-gen/raas-rollup';
import { QUERY_KEYS } from 'shared/types/react-query';

type Result = {
  vault: FeeVault;
  receipt: string;
  receiptBalance: string;
  receiptSigners: Array<string>;
  vaultBalance: string;
  minWithdrawalAmount: string;
  withdrawalNetwork: WithdrawalNetwork;
};
interface IUseWFStatus
  extends Omit<UseQueryOptions<unknown, Error, Result[]>, 'queryKey' | 'queryFn'> {
  params: {
    rollupId: string;
  };
}

export const useWFStatus = (opts: IUseWFStatus) =>
  useQuery({
    queryKey: [QUERY_KEYS.RAAS_WITHDRAW_FEE_STATUS, opts?.params],
    queryFn: async (): Promise<Result[]> => {
      const feeVaults = Object.values(FeeVault).filter(
        v => feeVaultFromJSON(v) !== FeeVault.UNRECOGNIZED,
      );

      const resps = await Promise.all(
        feeVaults.map(vault =>
          bff.get<WithdrawFeeStatusReply>('/bff/opstack/withdrawfee/status', {
            params: {
              opstackId: opts?.params?.rollupId,
              vault,
            },
          }),
        ),
      );

      const result = resps.map<Result>((resp, idx) => ({
        ...resp.data,
        vault: feeVaultFromJSON(feeVaults[idx]),
      }));

      return result;
    },
    ...opts,
  });
