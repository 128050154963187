import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { FC, useEffect, useState } from 'react';
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { bff } from 'shared/api';
import { Section } from 'shared/components';
import ConfirmationDialog from 'shared/components/ConfirmationDialog';
import {
  FormNumericTextField,
  FormSelectField,
  FormTextFieldBase,
  Label,
} from 'shared/components/form';
import { IconClose } from 'shared/components/icons/IconClose';
import { TableRowSkeleton } from 'shared/components/Skeleton/TableRowSkeleton';
import { Pagination } from 'shared/components/Table/Pagination';
import { useDisclosure, useTablePagination } from 'shared/hooks/ui';
import {
  AgreementCostInfoReply,
  AgreementCostListReply,
  CreateAgreementCostReply,
  CreateAgreementCostRequest,
  DeleteAgreementCostReply,
  EditAgreementCostReply,
  EditAgreementCostRequest,
} from 'shared/types/protoc-gen/bffbillsystem';
import { MUTATION_KEYS, QUERY_KEYS } from 'shared/types/react-query';
import { commify } from 'shared/utils/strings';
import { number, object, ObjectSchema, string } from 'yup';

export const CostListSection = () => {
  const [selectedCostId, setSelectedCostId] = useState<number>();

  const queryClient = useQueryClient();

  const { addAlert } = useAlerts();
  const { clientId } = useParams();

  const {
    onClose: onCloseDeleteConfirmationDialog,
    onOpen: onOpenDeleteConfirmationDialog,
    open: isOpenDeleteConfirmationDialog,
  } = useDisclosure();
  const {
    onClose: onCloseCostInfoDialog,
    onOpen: onOpenCostInfoDialog,
    open: isOpenCostInfoDialog,
  } = useDisclosure();
  const {
    onClose: onCloseCreateCostDialog,
    onOpen: onOpenCreateCostDialog,
    open: isOpenCreateCostDialog,
  } = useDisclosure();

  const { data: costList, isPending: isGetCostListPending } = useQuery({
    queryKey: [QUERY_KEYS.ADMIN_CLIENT_COST_LIST, { clientId }],
    queryFn: async () => {
      const res = await bff.get<AgreementCostListReply>(`bff/bill/cost`, {
        params: {
          agreementId: Number(clientId),
        },
      });

      return res.data;
    },
  });

  const { isPending: isCreateCostPending, mutate: createCost } = useMutation({
    mutationKey: [MUTATION_KEYS.ADMIN_CLIENT_COST_CREATE],
    mutationFn: async (cost: Omit<CreateAgreementCostRequest, 'agreementId'>) => {
      const res = await bff.post<CreateAgreementCostReply>(`bff/bill/cost`, {
        ...cost,
        agreementId: Number(clientId),
      } satisfies CreateAgreementCostRequest);

      return res.data;
    },
    onSuccess: () => {
      addAlert({
        title: 'Cost created successfully',
        severity: ALERT_SEVERITY.SUCCESS,
      });

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.ADMIN_CLIENT_COST_LIST, { clientId }],
      });
    },
    onError: () => {
      addAlert({
        title: 'Failed to create cost',
        severity: ALERT_SEVERITY.ERROR,
      });
    },
  });

  const { isPending: isUpdateCostPending, mutate: updateCost } = useMutation({
    mutationKey: [MUTATION_KEYS.ADMIN_CLIENT_COST_UPDATE],
    mutationFn: async (cost: Omit<EditAgreementCostRequest, 'agreementId'>) => {
      const res = await bff.put<EditAgreementCostReply>(`bff/bill/cost`, {
        ...cost,
        agreementId: Number(clientId),
        costId: cost.costId,
      } satisfies EditAgreementCostRequest);

      return res.data;
    },
    onSuccess: () => {
      addAlert({
        title: 'Cost updated successfully',
        severity: ALERT_SEVERITY.SUCCESS,
      });

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.ADMIN_CLIENT_COST_LIST, { clientId }],
      });
    },
    onError: () => {
      addAlert({
        title: 'Failed to create cost',
        severity: ALERT_SEVERITY.ERROR,
      });
    },
  });

  const { isPending: isDeleteCostPending, mutate: deleteCost } = useMutation({
    mutationKey: [MUTATION_KEYS.ADMIN_CLIENT_COST_DELETE],
    mutationFn: async (costId: number) => {
      const res = await bff.delete<DeleteAgreementCostReply>(`bff/bill/cost/${costId}`);

      return res.data;
    },
    onSuccess: () => {
      addAlert({
        title: 'Cost deleted successfully',
        severity: ALERT_SEVERITY.SUCCESS,
      });

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.ADMIN_CLIENT_COST_LIST, { clientId }],
      });
    },
    onError: () => {
      addAlert({
        title: 'Failed to delete cost',
        severity: ALERT_SEVERITY.ERROR,
      });
    },
  });

  const { filteredData, paginationCount, setCurrentPage } = useTablePagination({
    data: costList?.costInfo || [],
  });

  return (
    <>
      <CostInfoDialog
        actionButtonText="Create"
        loading={isCreateCostPending}
        onClose={onCloseCreateCostDialog}
        onValid={formValues => {
          createCost(formValues);
          onCloseCreateCostDialog();
        }}
        open={isOpenCreateCostDialog}
      />
      <CostInfoDialog
        actionButtonText="Save"
        costId={selectedCostId}
        loading={isUpdateCostPending}
        onClose={onCloseCostInfoDialog}
        onValid={formValues => {
          updateCost({
            ...formValues,
            month: formValues.month || '',
            costId: Number(selectedCostId),
          });
          onCloseCostInfoDialog();
        }}
        open={isOpenCostInfoDialog}
      />
      <ConfirmationDialog
        handleClose={onCloseDeleteConfirmationDialog}
        isLoading={isDeleteCostPending}
        onClose={onCloseDeleteConfirmationDialog}
        onConfirm={() => {
          onCloseDeleteConfirmationDialog();
          if (selectedCostId) deleteCost(selectedCostId);
        }}
        open={isOpenDeleteConfirmationDialog}
        title="Delete Payment"
      >
        <Typography>Are you sure you want to delete this payment?</Typography>
      </ConfirmationDialog>

      <Section
        data-testid="cost-list-section"
        renderSubHeaderEndContent={
          clientId && (
            <Button
              data-testid="add-client-cost-button"
              onClick={onOpenCreateCostDialog}
              size="small"
              variant="contained"
            >
              Create
            </Button>
          )
        }
        renderSubHeaderStartContent={
          isGetCostListPending ? (
            <CircularProgress data-testid="cost-list-loading" size={24} />
          ) : undefined
        }
        title="Costs"
      >
        <TableContainer>
          <Table stickyHeader sx={{ tableLayout: { xs: 'auto', lg: 'fixed' }, width: '100%' }}>
            <TableHead>
              <TableRow>
                {[
                  { label: 'ID' },
                  { label: 'Amount' },
                  { label: 'Currency' },
                  { label: 'Notes' },
                  { label: 'Month' },
                  { label: 'Details' },
                ].map(header => (
                  <TableCell key={header.label}>
                    <Typography variant="captionC">{header.label}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isGetCostListPending ? (
                Array.from({ length: 10 }).map((_, index) => (
                  <TableRowSkeleton cellCount={6} key={index} />
                ))
              ) : filteredData?.length > 0 ? (
                filteredData.map(cost => (
                  <TableRow key={[cost.agreementId, cost?.costId].join('-')}>
                    <TableCell>
                      <Typography variant="bodySmallM">{cost?.costId}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="bodySmall">{commify(cost?.amount)}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="bodySmall">{cost?.currency}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="bodySmall">{cost.notes}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="bodySmall">{cost?.month}</Typography>
                    </TableCell>
                    <TableCell>
                      {clientId && cost?.costId && (
                        <Stack direction="row" spacing={1}>
                          <Button
                            data-testid="view-cost-button"
                            onClick={() => {
                              setSelectedCostId(cost?.costId);
                              onOpenCostInfoDialog();
                            }}
                            size="small"
                            variant="outlined"
                          >
                            <Typography>View</Typography>
                          </Button>
                          <Button
                            data-testid="delete-cost-button"
                            onClick={() => {
                              setSelectedCostId(cost?.costId);
                              onOpenDeleteConfirmationDialog();
                            }}
                            size="small"
                            variant="outlined"
                          >
                            <Typography>Delete</Typography>
                          </Button>
                        </Stack>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Typography variant="bodySmall">No costs records</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <Pagination
              paginationOptions={{
                count: paginationCount,
                onChange: (_, page) => setCurrentPage(page),
              }}
              sx={{ pl: 5, py: 3 }}
            />
          </Table>
        </TableContainer>
      </Section>
    </>
  );
};

interface CostInfoFormValues {
  amount: number;
  currency: string;
  notes: string;
  month: string;
}

const CostInfoDialog: FC<
  DialogProps & {
    onInvalid?: SubmitErrorHandler<CostInfoFormValues>;
    onValid: SubmitHandler<CostInfoFormValues>;
    loading?: boolean;
    actionButtonText?: string;
    costId?: number;
  }
> = ({
  actionButtonText = 'Save',
  costId,
  loading,
  onClose,
  onInvalid,
  onValid,
  open,
  sx,
  ...props
}) => {
  const editMode = !!costId;

  const form = useForm<CostInfoFormValues>({
    defaultValues: {
      amount: 0,
      currency: '',
      notes: '',
    },
    resolver: yupResolver<CostInfoFormValues>(
      object().shape({
        amount: number().min(0).required(),
        currency: string().required(),
        notes: string().optional(),
        month: string()
          .required()
          .matches(/^\d{4}(0[1-9]|1[0-2])$/, 'Must be in YYYYMM format (e.g. 202502)'),
      }) as ObjectSchema<CostInfoFormValues>,
    ),
  });

  const handleClose = () => onClose?.({}, 'backdropClick');

  const { data: costInfo, isPending: isGetCostInfoPending } = useQuery({
    queryKey: [QUERY_KEYS.ADMIN_CLIENT_COST_INFO, { costId }],
    queryFn: async () => {
      const res = await bff.get<AgreementCostInfoReply>(`bff/bill/cost/${costId}`);

      return res.data;
    },
    enabled: !!costId,
  });

  useEffect(() => {
    if (costInfo) {
      form.reset({
        amount: costInfo.amount,
        currency: costInfo.currency,
        notes: costInfo.notes,
        month: costInfo.month,
      });
    }
  }, [costInfo, form]);

  return (
    <FormProvider {...form}>
      <Dialog
        component="form"
        onClose={onClose}
        onSubmit={form.handleSubmit(onValid, onInvalid)}
        open={open}
        sx={{
          '&>div>.MuiPaper-root': {
            p: 3,
            minWidth: { xs: '80vw', md: '60vw', lg: '40vw', xl: '30vw' },
          },
          ...sx,
        }}
        {...props}
      >
        <DialogTitle sx={{ px: 5, py: 3 }}>
          <Stack alignItems="center" direction="row" justifyContent="space-between">
            <Typography variant="h5">Cost Info</Typography>
            <IconButton onClick={handleClose}>
              <IconClose />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ px: 5, py: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Label label="Amount">
                <FormNumericTextField<CostInfoFormValues>
                  disabled={editMode && isGetCostInfoPending}
                  fieldConfig={{ name: 'amount' }}
                  helperTextShrink
                  hideLabel
                  id="amount_field"
                  placeholder="Enter amount per payment"
                />
              </Label>
            </Grid>
            <Grid item xs={12}>
              <Label label="Currency">
                <FormSelectField
                  disabled={editMode && isGetCostInfoPending}
                  fieldConfig={{ name: 'currency' }}
                  id="currency_field"
                  items={[
                    { label: 'USDT', value: 'USDT' },
                    { label: 'USDC', value: 'USDC' },
                    { label: 'ETH', value: 'ETH' },
                    { label: 'BNB', value: 'BNB' },
                  ]}
                  placeholder="Select currency"
                />
              </Label>
            </Grid>
            <Grid item xs={12}>
              <Label label="Notes">
                <FormTextFieldBase<CostInfoFormValues>
                  disabled={editMode && isGetCostInfoPending}
                  fieldConfig={{ name: 'notes' }}
                  helperTextShrink
                  id="notes_field"
                  minRows={4}
                  multiline
                  placeholder="Enter notes"
                />
              </Label>
            </Grid>
            <Grid item xs={12}>
              <Label label="Month">
                <FormTextFieldBase<CostInfoFormValues>
                  disabled={editMode && isGetCostInfoPending}
                  fieldConfig={{ name: 'month' }}
                  helperText="The month format should be YYYYMM e.g. 202501"
                  id="month_field"
                  placeholder="Enter month"
                />
              </Label>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ px: 5, py: 3 }}>
          <LoadingButton fullWidth loading={loading} type="submit" variant="contained">
            {actionButtonText}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};
