import dayjs from 'dayjs';
import { Timeframe } from 'pages/raas/hooks';
import { useMemo } from 'react';

import { DateFilterVariant, useDateRangeStore } from '../store';

export const useDateRangeFilterOptions = (
  variant: DateFilterVariant,
): {
  start: string;
  end: string;
  step: '1h' | '10m' | '1d' | '24h' | '48h';
  timeframe: Timeframe;
} => {
  const { selectedDateRange } = useDateRangeStore();

  const dateRange = useMemo(
    () => selectedDateRange[variant] ?? { from: new Date(), to: new Date() },
    [selectedDateRange, variant],
  );

  return useMemo(() => {
    return {
      start: getStartTime(dateRange),
      end: getEndTime(dateRange),
      step: getStep(dateRange),
      timeframe: getTimeframe(dateRange),
    };
  }, [dateRange]);
};

const getStartTime = (dateRange?: { from: Date; to: Date }) => {
  if (!dateRange?.from) {
    return dayjs().subtract(30, 'days').startOf('day').format('YYYY-MM-DD');
  }

  if (dayjs(dateRange.from).format('YYYY-MM-DD') === dayjs(dateRange.to).format('YYYY-MM-DD')) {
    return dayjs(dateRange.from).format('YYYY-MM-DD HH:mm:ss');
  }

  return dayjs(dateRange.from).format('YYYY-MM-DD');
};

const getEndTime = (dateRange?: { from: Date; to: Date }) => {
  if (!dateRange?.to) {
    return dayjs().endOf('day').format('YYYY-MM-DD');
  }

  if (dayjs(dateRange.from).format('YYYY-MM-DD') === dayjs(dateRange.to).format('YYYY-MM-DD')) {
    return dayjs(dateRange.to).format('YYYY-MM-DD HH:mm:ss');
  }

  return dayjs(dateRange.to).format('YYYY-MM-DD');
};

const getStep = (dateRange?: { from: Date; to: Date }) => {
  if (!dateRange?.from || !dateRange?.to) return '24h';

  const daysDiff = dayjs(dateRange.to).diff(dayjs(dateRange.from), 'days');

  if (daysDiff <= 1) return '1h';
  if (daysDiff <= 7) return '24h';
  if (daysDiff <= 30) return '24h';

  return '48h';
};

const getTimeframe = (dateRange?: { from: Date; to: Date }): Timeframe => {
  if (!dateRange?.from || !dateRange?.to) return 'month';

  const daysDiff = dayjs(dateRange.to).diff(dayjs(dateRange.from), 'days');

  if (daysDiff >= 29) return 'month';
  if (daysDiff >= 1) return 'week';

  return 'day';
};
