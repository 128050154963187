import { getNoDataTextForCustomToken } from 'pages/raas/DeploymentDetails/utils';
import { useRaasDeployment, useRaasMetrics } from 'pages/raas/hooks';
import { useParams } from 'react-router-dom';
import { Metric } from 'shared/components/metric';
import { Panel } from 'shared/types/protoc-gen/raas-metrics';

import { useDateRangeFilterOptions } from '../../hooks/useDateRangeFilterOptions';
import { DateFilterVariant } from '../../store';

export const DepositTxnsGrowthMetric = () => {
  const { rollupId } = useParams();
  const { data: rollup } = useRaasDeployment({ rollupId });

  const { end, start, step, timeframe } = useDateRangeFilterOptions(
    DateFilterVariant.BRIDGE_METRICS_DEPOSITS_SECTION,
  );

  const { data: result, isPending } = useRaasMetrics({
    panel: Panel.PANEL_OPSTACK_DEPOSIT_COUNT,
    timeframe,
    rollupId,
    start,
    end,
    step,
  });

  return (
    <Metric
      data={result}
      description={'Growth trend of cumulative deposit transaction numbers'}
      id="deposit-txns-growth"
      loading={isPending}
      noDataText={getNoDataTextForCustomToken(rollup)}
      title="Deposit txns growth"
    />
  );
};
