import { useBlockscoutMetrics } from 'pages/raas/hooks';
import { Metric } from 'shared/components/metric';

import { useDateRangeFilterOptions } from '../../hooks/useDateRangeFilterOptions';
import { DateFilterVariant } from '../../store';

export const AccountsGrowthMetric = () => {
  const {
    end: to,
    start: from,
    timeframe,
  } = useDateRangeFilterOptions(DateFilterVariant.ROLLUP_STATS_EOA_ACCOUNTS_SECTION);

  const { data: result, isPending } = useBlockscoutMetrics({
    variant: 'accountsGrowth',
    timeframe,
    from,
    to,
  });

  return (
    <Metric
      data={result}
      description={'Cumulative accounts number per period'}
      id={'accounts-growth'}
      loading={isPending}
      title={'Accounts growth'}
    />
  );
};
