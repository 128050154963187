import { IconButton, Stack, Tooltip, Typography, TypographyProps } from '@mui/material';
import { useCopyToClipboard } from 'shared/hooks/ui';
import { truncate } from 'shared/utils/strings';

import { IconCopy } from '../icons/IconCopy';

interface ICopyableAddress extends TypographyProps {
  address: string;
  truncated?: boolean;
}

const CopyableAddress: React.FC<ICopyableAddress> = ({
  address,
  sx,
  truncated = true,
  ...typographyProps
}) => {
  const [, copy] = useCopyToClipboard();

  return (
    <Stack
      alignItems="center"
      direction="row"
      onClick={() => copy(address)}
      role="button"
      spacing={1}
    >
      {truncated ? (
        <Tooltip
          arrow
          title={
            <Typography
              sx={{ fontSize: '10px', color: theme => theme.colors.functional.text.primary }}
              variant="caption"
            >
              {address}
            </Typography>
          }
        >
          <Typography sx={{ ...sx }} {...typographyProps}>
            {truncate(address)}
          </Typography>
        </Tooltip>
      ) : (
        <Typography sx={{ ...sx }} {...typographyProps}>
          {address}
        </Typography>
      )}

      <IconButton onClick={() => copy(address)} size="small">
        <IconCopy
          sx={theme => ({
            height: '18px',
            width: '18px',
            color: theme.colors.functional.container.primary,
          })}
        />
      </IconButton>
    </Stack>
  );
};

export default CopyableAddress;
