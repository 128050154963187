import { useRaasMetrics } from 'pages/raas/hooks';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Metric } from 'shared/components/metric';
import { Panel } from 'shared/types/protoc-gen/raas-metrics';

import { useDateRangeFilterOptions } from '../../hooks/useDateRangeFilterOptions';
import { DateFilterVariant } from '../../store';

interface IRpcCountMetric {
  instance: string;
}

const RpcCountMetric: React.FC<IRpcCountMetric> = ({ instance }) => {
  const { rollupId } = useParams();

  const { end, start, step, timeframe } = useDateRangeFilterOptions(
    DateFilterVariant.DAILY_RPC_COUNT_SECTION,
  );

  const { data: result, isPending } = useRaasMetrics({
    panel: Panel.PANEL_OPSTACK_DAILY_RPC_COUNT,
    timeframe,
    rollupId,
    start,
    end,
    step,
    rpcInstance: instance,
  });

  return (
    <Metric
      chartType="bar"
      data={result}
      hideLegend
      hideMoreButton
      id="rpc-count"
      loading={isPending}
      xAxisProps={{
        dataKey: 'date',
      }}
      yAxisProps={{
        tickFormatter: value => new Intl.NumberFormat().format(value),
      }}
    />
  );
};

export default RpcCountMetric;
