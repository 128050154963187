import { Chip, ChipProps, Typography } from '@mui/material';
import { ConditionalTooltip } from 'shared/components/Tooltip/ConditionalTooltip';

export const PaymentStatusChip = ({ status }: { status: string }) => {
  return (
    <ConditionalTooltip enabled title={status}>
      <Chip
        color={getChipColor(status)}
        label={<Typography variant="captionM">{status}</Typography>}
        size="small"
        variant="outlined"
      />
    </ConditionalTooltip>
  );
};

const getChipColor = (status: string): ChipProps['color'] => {
  switch (status) {
    case 'Healthy':
      return 'success';

    case 'Active':
      return 'primary';
    case 'Pause':
      return 'secondary';
    case 'Terminated':
      return 'error';

    case 'Pending Invoice':
      return 'warning';
    case 'Awaiting':
      return 'warning';
    case 'Paid':
      return 'primary';
    case 'Overdue':
      return 'error';

    default:
      return 'default';
  }
};
