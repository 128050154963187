import dayjs from 'dayjs';
import { Timeframe } from 'pages/raas/hooks';

export const getTimeFilterOpts = () => {
  const [now, template] = [dayjs(), 'YYYY-MM-DD HH:mm:ss'];
  const [start, end] = [now.subtract(1, 'hour').format(template), now.format(template)];
  const step = '5m';

  return {
    start,
    end,
    step,
    template,
  };
};

export const getRpsTemplate = (timeframe: Timeframe | undefined) => {
  switch (timeframe) {
    case 'day':
      return 'YYYY-MM-DD HH:mm';
    case 'hour':
      return 'YYYY-MM-DD hh:mm';
    default:
      return 'YYYY-MM-DD';
  }
};
