import { useRaasMetrics } from 'pages/raas/hooks';
import { useParams } from 'react-router-dom';
import { Metric } from 'shared/components/metric';
import { Panel } from 'shared/types/protoc-gen/raas-metrics';

import { useDateRangeFilterOptions } from '../../hooks/useDateRangeFilterOptions';
import { useYAxisMinValue } from '../../hooks/useYAxisMinValue';
import { DateFilterVariant } from '../../store';

export const ProposerTxCountMetric = () => {
  const { rollupId } = useParams();
  const { end, start, step, timeframe } = useDateRangeFilterOptions(
    DateFilterVariant.PROPOSER_BALANCE_BALANCE_SECTION,
  );

  const { data: result, isPending } = useRaasMetrics({
    panel: Panel.PANEL_OPSTACK_PROPOSER_TX_COUNT,
    rollupId,
    timeframe,
    start,
    end,
    step,
  });

  const { data: yAxisMinValue = 0 } = useYAxisMinValue({
    panel: Panel.PANEL_OPSTACK_BATCHER_TX_COUNT,
    timeframe,
    result,
    rollupId,
  });

  return (
    <Metric
      curveType="natural"
      data={result}
      description={'Cumulative number of transactions proposed by the proposer over time'}
      id={'proposer-tx-count'}
      loading={isPending}
      title={'Proposer tx count'}
      yAxisProps={{
        domain: [yAxisMinValue],
      }}
    />
  );
};
