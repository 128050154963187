import { useBlockscoutMetrics } from 'pages/raas/hooks';
import { Metric } from 'shared/components/metric';
import { roundDecimals } from 'shared/utils/strings';

import { useDateRangeFilterOptions } from '../../hooks/useDateRangeFilterOptions';
import { DateFilterVariant } from '../../store';

export interface IAverageGasPriceMetric {
  dateFilterVariant?: DateFilterVariant;
}

export const AverageGasPriceMetric = ({ dateFilterVariant }: IAverageGasPriceMetric) => {
  const {
    end: to,
    start: from,
    timeframe,
  } = useDateRangeFilterOptions(dateFilterVariant ?? DateFilterVariant.FEE_TRACKER_GAS_SECTION);

  const { data: result, isPending } = useBlockscoutMetrics({
    variant: 'averageGasPrice',
    l2: true,
    timeframe,
    from,
    to,
  });

  return (
    <Metric
      data={result}
      description={'Average gas price for the period (Gwei)'}
      id={'average-gas-price'}
      loading={isPending}
      title={'Average gas price'}
      tooltipValueFormatter={value => `${roundDecimals(value)} Gwei`}
      yAxisProps={{
        tickFormatter: value => `${value} Gwei`,
      }}
    />
  );
};
