import { Grid } from '@mui/material';
import { Section } from 'shared/components';
import { DateRangePicker } from 'shared/components/Date';
import { MetricGridItem } from 'shared/components/metric';

import { DateFilterVariant } from '../../store';
import { IRpcMonitoringSections } from './RpcUsageMonitoringView';
import RpsMetric from './RpsMetric';

const RpsMetricSection: React.FC<IRpcMonitoringSections> = ({ instance }) => {
  return (
    <Section
      renderSubHeaderEndContent={<DateRangePicker variant={DateFilterVariant.RPS_METRIC_SECTION} />}
      title="RPS"
    >
      <Grid container>
        <MetricGridItem item xs={12}>
          <RpsMetric instance={instance} />
        </MetricGridItem>
      </Grid>
    </Section>
  );
};

export default RpsMetricSection;
