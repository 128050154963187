import { LoadingButton } from '@mui/lab';
import {
  Box,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useRaasMetrics } from 'pages/raas/hooks';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Section } from 'shared/components';
import { DateRangePicker } from 'shared/components/Date';
import { AnimatedIconChevronDown } from 'shared/components/icons/AnimatedIconChevronDown';
import { MetricGridItem } from 'shared/components/metric';
import { useMenuDisclosure } from 'shared/hooks/ui/useMenuDisclosure';
import { Panel } from 'shared/types/protoc-gen/raas-metrics';

import { useDateRangeFilterOptions } from '../../hooks/useDateRangeFilterOptions';
import { DateFilterVariant } from '../../store';
import { IRpcMonitoringSections } from './RpcUsageMonitoringView';
import { RpsByMethodsMetric } from './RpsByMethodsMetric';

const RpsByMethodSection: React.FC<IRpcMonitoringSections> = ({ instance }) => {
  const { rollupId } = useParams();

  const { end, start, step, timeframe } = useDateRangeFilterOptions(
    DateFilterVariant.RPS_CHOOSE_METHOD_SECTION,
  );

  const [selectedMethod, setSelectedMethod] = useState<string>('All methods');

  const { data, isPending } = useRaasMetrics({
    panel: Panel.PANEL_OPSTACK_RPS_BY_METHOD,
    timeframe,
    rollupId,
    start,
    end,
    step,
    rpcInstance: instance,
  });

  const {
    anchorEl: methodsMenuAnchorEl,
    onClose: onCloseMethodsMenu,
    onOpen: onOpenMethodsMenu,
    open: openedMethodsMenu,
  } = useMenuDisclosure();

  const methods = useMemo(() => {
    return [
      'All methods',
      ...Array.from(new Set(data?.data.flatMap(item => Object.keys(item))))
        .filter(val => val !== 'date')
        .sort((a, b) => a.localeCompare(b)),
    ];
  }, [data]);

  const filteredDataKeys = useMemo(() => {
    if (!data) return [];

    if (selectedMethod === 'All methods') {
      return data.dataKeys;
    }

    return data.dataKeys
      .filter(dataKey => dataKey.name === selectedMethod)
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [selectedMethod, data]);

  useEffect(() => {
    if (!selectedMethod && methods.length > 0) {
      setSelectedMethod(methods[0]);
    }
  }, [methods, selectedMethod]);

  return (
    <Section
      renderSubHeaderEndContent={
        <DateRangePicker variant={DateFilterVariant.RPS_CHOOSE_METHOD_SECTION} />
      }
      renderSubHeaderStartContent={
        <>
          <LoadingButton
            loading={isPending}
            onClick={onOpenMethodsMenu}
            sx={theme => ({
              backgroundColor: 'transparent !important',
              '&, &:hover': {
                background: '#fff',
                color: theme.colors.functional.text.primary,
                p: 0,
              },
            })}
          >
            <Stack direction="row" spacing="32px" sx={{ p: '16px' }}>
              <Typography variant="bodySmallM">{selectedMethod}</Typography>
              <AnimatedIconChevronDown open={openedMethodsMenu} />
            </Stack>
          </LoadingButton>
          <Menu
            anchorEl={methodsMenuAnchorEl}
            onClose={onCloseMethodsMenu}
            open={openedMethodsMenu}
          >
            {methods.map(method => {
              return (
                <MenuItem
                  key={method}
                  onClick={() => {
                    setSelectedMethod(method);
                    onCloseMethodsMenu();
                  }}
                >
                  {method}
                </MenuItem>
              );
            })}
          </Menu>
        </>
      }
      title="RPS by method"
    >
      <Grid container>
        <MetricGridItem item md={9} xs={12}>
          <RpsByMethodsMetric instance={instance} method={selectedMethod} />
        </MetricGridItem>

        <Grid item md={3} xs={12}>
          <TableContainer sx={{ maxHeight: '360px', overflowY: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  {['Method Name'].map(header => (
                    <TableCell key={header}>
                      <Typography variant="captionC">{header}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredDataKeys.map(dataKey => (
                  <TableRow key={dataKey.name}>
                    <TableCell>
                      <Stack alignItems="center" direction="row" spacing={1}>
                        <Box
                          borderRadius="20px"
                          height="10px"
                          sx={{ backgroundColor: dataKey.color }}
                          width="20px"
                        ></Box>
                        <Typography>{dataKey.name.toLocaleString()}</Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Section>
  );
};

export default RpsByMethodSection;
