import { Grid, Stack, SxProps, Theme, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useRaasMetrics } from 'pages/raas/hooks';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Section } from 'shared/components';
import { DateRangePicker } from 'shared/components/Date';
import { Panel } from 'shared/types/protoc-gen/raas-metrics';
import { commify } from 'shared/utils/strings';

import { useTimeFilterOptions } from '../../hooks/useTimeFilterOptions';
import { DateFilterVariant } from '../../store';
import RpcCountMetric from './RpcCountMetric';
import { IRpcMonitoringSections } from './RpcUsageMonitoringView';

interface IRpcCountCard {
  title: string;
  value: string | number;
  sx?: SxProps<Theme>;
}

const RpcCountSection: React.FC<IRpcMonitoringSections> = ({ instance }) => {
  const { rollupId } = useParams();

  const { end, step, timeframe } = useTimeFilterOptions('month');

  const { data: result } = useRaasMetrics({
    panel: Panel.PANEL_OPSTACK_DAILY_RPC_COUNT,
    timeframe,
    rollupId,
    start: dayjs().startOf('month').format('YYYY-MM-DD'),
    end,
    step,
    rpcInstance: instance,
  });

  const rpcCounts = useMemo(() => {
    const todayCount = result?.data.find(
      data => dayjs(data.date).format('DD/MM/YYYY') === dayjs().format('DD/MM/YYYY'),
    );

    const currentMonthTotal = result?.data.reduce(
      (acc, data) => acc + (Number(data?.['daily rpc count']) ?? 0),
      0,
    );

    return {
      rpcCountToday: commify(todayCount?.['daily rpc count'] ?? 0),
      rpcCountThisMonth: commify(currentMonthTotal ?? 0),
    };
  }, [result]);

  return (
    <Grid container>
      <Grid
        item
        sx={{ borderRight: theme => `1px solid ${theme.colors.functional.subject.border}` }}
        xs={8}
      >
        <Section
          renderSubHeaderEndContent={
            <DateRangePicker variant={DateFilterVariant.DAILY_RPC_COUNT_SECTION} />
          }
          title="Daily RPC Count"
        >
          <Grid container>
            <Grid item xs={12}>
              <RpcCountMetric instance={instance} />
            </Grid>
          </Grid>
        </Section>
      </Grid>

      <Grid item xs={4}>
        <Stack height="100%" sx={{ backgroundColor: '#FFFFFF' }}>
          <RpcCountCard
            sx={{ borderBottom: theme => `1px solid ${theme.colors.functional.subject.border}` }}
            title="RPC Count today"
            value={rpcCounts.rpcCountToday}
          />

          <RpcCountCard title="RPC Count this month" value={rpcCounts.rpcCountThisMonth} />
        </Stack>
      </Grid>
    </Grid>
  );
};

const RpcCountCard: React.FC<IRpcCountCard> = ({ sx, title, value }) => {
  return (
    <Stack alignItems="center" direction="row" height="100%" sx={{ p: '40px', ...sx }}>
      <Stack spacing="20px">
        <Typography variant="captionC">{title}</Typography>
        <Typography fontSize="40px" variant="h4">
          {value}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default RpcCountSection;
