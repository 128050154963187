import { Tooltip, Typography, TypographyProps } from '@mui/material';
import { ExternalLink } from 'pages/raas/components';
import React from 'react';
import { truncate } from 'shared/utils/strings';

interface IAddressLink extends TypographyProps {
  href: string;
  address: string;
  separator?: string;
  leadingChars?: number;
  trailingChars?: number;
}

const AddressLink: React.FC<IAddressLink> = ({
  address,
  href,
  leadingChars = 8,
  separator = '...',
  sx,
  trailingChars = 6,
  ...typographyProps
}) => {
  return (
    <ExternalLink href={href} sx={{ textDecoration: 'underline', ...sx }}>
      <Tooltip
        arrow
        title={
          <Typography
            sx={{ fontSize: '10px', color: theme => theme.colors.functional.text.primary }}
            variant="caption"
          >
            {address}
          </Typography>
        }
      >
        <Typography {...typographyProps}>
          {truncate(address, separator, leadingChars, trailingChars)}
        </Typography>
      </Tooltip>
    </ExternalLink>
  );
};

export default AddressLink;
