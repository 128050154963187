import { DateRangePicker } from 'shared/components/Date';
import { MetricGridItem } from 'shared/components/metric/MetricGridItem';
import { Section } from 'shared/components/Section';

import { DateFilterVariant } from '../../store';
import { L2FinalizedMetric } from './L2FinalizedMetric';
import { L2SafeMetric } from './L2SafeMetric';
import { L2UnsafeMetric } from './L2UnsafeMetric';

export const L2Section = () => {
  return (
    <Section
      renderGridContent={
        <>
          <MetricGridItem item>
            <L2UnsafeMetric />
          </MetricGridItem>
          <MetricGridItem item>
            <L2SafeMetric />
          </MetricGridItem>
          <MetricGridItem item>
            <L2FinalizedMetric />
          </MetricGridItem>
          <MetricGridItem item>
            <></>
          </MetricGridItem>
        </>
      }
      renderSubHeaderEndContent={<DateRangePicker variant={DateFilterVariant.L2_NODES_SECTION} />}
      sectionContentSx={{ p: 0 }}
      title="Nodes"
    />
  );
};
