export default `
# Autonome FAQ

### How do I deploy an AI agent?
For detailed instructions on deploying an AI agent through Autonome, please refer to our [deployment guide](https://docs.altlayer.io/altlayer-documentation/autonome/deploy-ai-agent#deploying-an-ai-agent-via-autonome).

### Is there an API for Autonome?
You can find comprehensive API documentation in our [API guide](https://docs.altlayer.io/altlayer-documentation/autonome/autonome-api-guide#guide).

### Can I upload my own agent framework?
Yes, you can! Check out our guide on [uploading custom agent frameworks](https://docs.altlayer.io/altlayer-documentation/autonome/uploading-your-own-agent-framework#guide).

### What should I do if my uploaded framework deployment fails?
If you're experiencing issues with framework deployments, consult our [troubleshooting guide](https://docs.altlayer.io/altlayer-documentation/autonome/uploading-your-own-agent-framework#troubleshooting-bad-deployments-with-uploaded-frameworks).

### Why is my Twitter/X account being flagged as inauthentic?
For information about X/Twitter account authenticity issues, see our [account flagging guide](https://docs.altlayer.io/altlayer-documentation/autonome/twitter-x-login-troubleshooting-guide#x-twitter-flagging-the-account-as-inauthentic).

### Why can't my AI agent log in to Twitter/X?
If your AI agent is having trouble logging into X/Twitter, reference our [login troubleshooting guide](https://docs.altlayer.io/altlayer-documentation/autonome/twitter-x-login-troubleshooting-guide#ai-agent-unable-to-log-in-to-x-twitter-account).` as const;
