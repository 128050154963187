import { TxPoolMetric } from 'pages/raas/DeploymentDetails/views/OverviewView/TxPoolMetric';
import { DateRangePicker } from 'shared/components/Date';
import { MetricGridItem } from 'shared/components/metric/MetricGridItem';
import { Section } from 'shared/components/Section';

import { DateFilterVariant } from '../../store';
import { CPUMetric } from './CPUMetric';
import { MemoryMetric } from './MemoryMetric';

export const BlockchainStatisticsView = () => {
  return (
    <>
      <Section
        renderGridContent={
          <>
            <MetricGridItem item>
              <TxPoolMetric
                dateFilterVariant={DateFilterVariant.BLOCKCHAIN_STATS_GENERAL_SECTION}
              />
            </MetricGridItem>
            <MetricGridItem item>
              <CPUMetric />
            </MetricGridItem>
            <MetricGridItem item>
              <MemoryMetric />
            </MetricGridItem>
          </>
        }
        renderSubHeaderEndContent={
          <DateRangePicker variant={DateFilterVariant.BLOCKCHAIN_STATS_GENERAL_SECTION} />
        }
        sectionContentSx={{ p: 0 }}
        title="Blockchain Statistics"
      />
    </>
  );
};
