import {
  Button,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import dayjs from 'dayjs';
import { PaymentStatusChip } from 'pages/admin/ManageClients/PaymentStatusChip';
import { ADMIN_PATHS } from 'pages/admin/routes';
import { ExternalLink } from 'pages/raas/components';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { bff } from 'shared/api';
import ConfirmationDialog from 'shared/components/ConfirmationDialog';
import { Section } from 'shared/components/Section';
import { TableRowSkeleton } from 'shared/components/Skeleton/TableRowSkeleton';
import { Pagination } from 'shared/components/Table/Pagination';
import { useDisclosure, useTablePagination } from 'shared/hooks/ui';
import { DeletePaymentReply, PaymentListReply } from 'shared/types/protoc-gen/bffbillsystem';
import { QUERY_KEYS } from 'shared/types/react-query';
import { commify, truncate } from 'shared/utils/strings';

export const PaymentListSection = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { addAlert } = useAlerts();
  const { clientId } = useParams();

  const [selectedPaymentId, setSelectedPaymentId] = useState<number>();

  const {
    onClose: onCloseDeleteConfirmationDialog,
    onOpen: onOpenDeleteConfirmationDialog,
    open: isOpenDeleteConfirmationDialog,
  } = useDisclosure();

  const { data: paymentList, isPending: getPaymentListPending } = useQuery({
    queryKey: [QUERY_KEYS.ADMIN_CLIENT_PAYMENT_LIST, { clientId }],
    queryFn: async () => {
      const res = await bff.get<PaymentListReply>(`bff/bill/payments`, {
        params: {
          agreement_id: Number(clientId),
        },
      });

      return res.data;
    },
  });
  const { filteredData, paginationCount, setCurrentPage } = useTablePagination({
    data: paymentList?.paymentInfo ?? [],
  });

  const { isPending: isDeletePaymentPending, mutate: deletePayment } = useMutation({
    mutationFn: async () => {
      const res = await bff.delete<DeletePaymentReply>(`/bff/bill/payments/${selectedPaymentId}`);

      return res.data;
    },
    onSuccess: () => {
      addAlert({
        severity: ALERT_SEVERITY.SUCCESS,
        title: 'Payment deleted successfully',
        desc: 'The payment has been deleted successfully',
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.ADMIN_CLIENT_PAYMENT_LIST, { clientId }],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.ADMIN_CLIENT_PAYMENT_TRACKER, { clientId }],
      });
    },
    onError: () => {
      addAlert({
        severity: ALERT_SEVERITY.ERROR,
        title: 'Failed to delete payment',
        desc: 'An error occurred while deleting the payment',
      });
    },
  });

  return (
    <>
      <ConfirmationDialog
        handleClose={onCloseDeleteConfirmationDialog}
        isLoading={isDeletePaymentPending}
        onClose={onCloseDeleteConfirmationDialog}
        onConfirm={() => {
          onCloseDeleteConfirmationDialog();
          deletePayment();
        }}
        open={isOpenDeleteConfirmationDialog}
        title="Delete Payment"
      >
        <Typography>Are you sure you want to delete this payment?</Typography>
      </ConfirmationDialog>

      <Section
        data-testid="payment-list-section"
        renderSubHeaderEndContent={
          clientId && (
            <Button
              data-testid="add-client-payment-button"
              onClick={() =>
                navigate(ADMIN_PATHS.MANAGE_CLIENTS_CREATE_PAYMENT.replace(':clientId', clientId))
              }
              size="small"
              variant="contained"
            >
              Create
            </Button>
          )
        }
        renderSubHeaderStartContent={
          getPaymentListPending ? (
            <CircularProgress data-testid="payment-list-loading" size={24} />
          ) : undefined
        }
        title="Payments"
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {[
                  'ID',
                  'Type',
                  'Sub type',
                  'Amount',
                  'Currency',
                  'Status',
                  'Invoice date',
                  'Payment date',
                  'Payment txhash',
                  'Details',
                ].map(header => (
                  <TableCell key={header}>
                    <Typography variant="captionC">{header}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {getPaymentListPending ? (
                Array.from({ length: 10 }).map((_, index) => (
                  <TableRowSkeleton cellCount={10} key={index} />
                ))
              ) : filteredData?.length > 0 ? (
                filteredData.map(payment => (
                  <TableRow key={payment?.paymentId}>
                    <TableCell>
                      <Typography variant="bodySmallM">{payment?.paymentId}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="bodySmall">{payment?.type}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="bodySmall">{payment?.subType}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="bodySmall">{commify(payment?.amount)}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="bodySmall">{payment?.currency}</Typography>
                    </TableCell>
                    <TableCell>
                      {payment?.status && <PaymentStatusChip status={payment?.status} />}
                    </TableCell>
                    <TableCell>
                      <Stack alignItems="center" direction="row" spacing={1}>
                        {payment?.invoiceTime && (
                          <Typography variant="bodySmall">
                            {dayjs(payment?.invoiceTime).format('YYYY/MM/DD')}
                          </Typography>
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Stack alignItems="center" direction="row" spacing={1}>
                        {payment?.paymentTime && (
                          <Typography variant="bodySmall">
                            {dayjs(payment?.paymentTime).format('YYYY/MM/DD')}
                          </Typography>
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <ExternalLink
                        href={`https://etherscan.io/tx/${payment?.paymentTxhash}`}
                        variant="caption"
                      >
                        {truncate(payment?.paymentTxhash)}
                      </ExternalLink>
                    </TableCell>
                    <TableCell>
                      {clientId && payment?.paymentId && (
                        <Stack direction="row" spacing={1}>
                          <Button
                            data-testid="view-payment-button"
                            onClick={() =>
                              navigate(
                                ADMIN_PATHS.MANAGE_CLIENTS_PAYMENT_DETAILS.replace(
                                  ':clientId',
                                  clientId,
                                ).replace(':paymentId', payment?.paymentId.toString()),
                              )
                            }
                            size="small"
                            variant="outlined"
                          >
                            <Typography>View</Typography>
                          </Button>
                          <Button
                            data-testid="delete-payment-button"
                            onClick={() => {
                              setSelectedPaymentId(payment?.paymentId);
                              onOpenDeleteConfirmationDialog();
                            }}
                            size="small"
                            variant="outlined"
                          >
                            <Typography>Delete</Typography>
                          </Button>
                        </Stack>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={10}>
                    <Typography variant="bodySmall">No payment records</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <Pagination
              paginationOptions={{
                count: paginationCount,
                onChange: (_, page) => setCurrentPage(page),
              }}
              sx={{ pl: 5, py: 3 }}
            />
          </Table>
        </TableContainer>
      </Section>
    </>
  );
};
