import { Skeleton, Stack, StackOwnProps, SxProps, Typography } from '@mui/material';
import { RaasMetricsData, RaasMetricsResult, Timeframe } from 'pages/raas/hooks';
import { FC, PropsWithChildren } from 'react';
import { XAxisProps, YAxisProps } from 'recharts';
import { CurveType } from 'recharts/types/shape/Curve';
import { AreaChart, BarChart, Formatter, LineChart } from 'shared/components/charts';
import { useDisclosure } from 'shared/hooks/ui';
import { useMenuDisclosure } from 'shared/hooks/ui/useMenuDisclosure';

import {
  IMetricContainerMoreMenu,
  MetricContainer,
  MetricContainerMoreMenu,
  MetricExpandDrawer,
} from '.';

export interface IMetric {
  id?: string;
  title?: string;
  description?: string;
  data?: RaasMetricsResult<RaasMetricsData>;
  loading?: boolean;
  tooltipKeyFormatter?: Formatter;
  tooltipValueFormatter?: Formatter;
  yAxisProps?: YAxisProps;
  xAxisProps?: XAxisProps;
  chartType?: 'area' | 'line' | 'bar';
  curveType?: CurveType;
  legendDirection?: StackOwnProps['direction'];
  legendSpacing?: StackOwnProps['spacing'];
  hideMoreButton?: boolean;
  hideTooltipKeys?: boolean;
  hideTooltipKeysSvg?: boolean;
  hideLegend?: boolean;
  responsiveContainerSx?: SxProps;
  moreMenuProps?: Partial<IMetricContainerMoreMenu>;
  isRefetching?: boolean;
  noDataText?: string;
  emptyDataText?: string;
  timeframe?: Timeframe;
}

export const Metric: React.FC<IMetric> = ({
  chartType,
  curveType,
  data: result,
  description,
  emptyDataText = 'No data available.',
  hideLegend,
  hideMoreButton,
  hideTooltipKeys,
  hideTooltipKeysSvg,
  id,
  isRefetching,
  legendDirection,
  legendSpacing,
  loading,
  moreMenuProps,
  noDataText = 'Insufficient data for the selected time period.',
  responsiveContainerSx,
  timeframe,
  title,
  tooltipKeyFormatter,
  tooltipValueFormatter,
  xAxisProps,
  yAxisProps,
}) => {
  const {
    onClose: onCloseExpandedDialog,
    onOpen: onOpenExpandedDialog,
    open: openedExpandedDialog,
  } = useDisclosure();
  const {
    anchorEl: anchorElMoreMenu,
    onClose: onCloseMoreMenu,
    onOpen: onOpenMoreMenu,
    open: openedMoreMenu,
  } = useMenuDisclosure();

  return (
    <>
      <MetricContainer
        description={description}
        onClickMoreButton={onOpenMoreMenu}
        renderMenu={
          <MetricContainerMoreMenu
            anchorEl={anchorElMoreMenu}
            onClickExpand={onOpenExpandedDialog}
            onClose={onCloseMoreMenu}
            open={openedMoreMenu}
            {...moreMenuProps}
          />
        }
        responsiveContainerSx={{ px: loading ? '40px' : 0, ...responsiveContainerSx }}
        showMoreButton={!hideMoreButton}
        showSpinner={isRefetching}
        title={title}
      >
        {loading ? (
          <Skeleton height="100%" sx={{ width: '50%' }} variant="rectangular" />
        ) : !result?.data ? (
          <CenteredTypography>{emptyDataText}</CenteredTypography>
        ) : result?.data.length === 0 ? (
          <CenteredTypography>{noDataText}</CenteredTypography>
        ) : chartType === 'line' ? (
          <LineChart
            curveType={curveType}
            data={result?.data}
            dataKeys={result?.dataKeys}
            hideLegend={hideLegend ?? result?.dataKeys.length === 1}
            hideTooltipKeys={hideTooltipKeys ?? result?.dataKeys.length === 1}
            hideTooltipKeysSvg={hideTooltipKeysSvg ?? result?.dataKeys.length === 1}
            id={id}
            legendDirection={legendDirection}
            legendSpacing={legendSpacing}
            tooltipValueFormatter={tooltipValueFormatter}
            xAxisProps={xAxisProps}
            yAxisProps={yAxisProps}
          />
        ) : chartType === 'bar' ? (
          <BarChart
            data={result.data}
            dataKeys={result.dataKeys}
            hideLegend={hideLegend ?? result.dataKeys.length === 1}
            tooltipValueFormatter={tooltipValueFormatter}
            xAxisProps={xAxisProps}
            yAxisProps={yAxisProps}
          />
        ) : (
          <AreaChart
            curveType={curveType}
            data={result?.data}
            dataKeys={result?.dataKeys}
            hideLegend={hideLegend ?? result?.dataKeys.length === 1}
            hideTooltipKeys={hideTooltipKeys ?? result?.dataKeys.length === 1}
            id={id}
            timeframe={timeframe}
            tooltipKeyFormatter={tooltipKeyFormatter}
            tooltipValueFormatter={tooltipValueFormatter}
            xAxisProps={xAxisProps}
            yAxisProps={yAxisProps}
          />
        )}
      </MetricContainer>

      <MetricExpandDrawer
        chartId={`${id}-expanded`}
        chartType={chartType}
        curveType={curveType}
        data={result}
        description={description}
        hideLegend={hideLegend}
        hideTooltipKeys={hideTooltipKeys}
        hideTooltipKeysSvg={hideTooltipKeysSvg}
        legendDirection={legendDirection}
        legendSpacing={legendSpacing}
        loading={loading}
        onClose={onCloseExpandedDialog}
        open={openedExpandedDialog}
        title={title}
        tooltipValueFormatter={tooltipValueFormatter}
        xAxisProps={xAxisProps}
        yAxisProps={yAxisProps}
      />
    </>
  );
};

const CenteredTypography: FC<PropsWithChildren> = ({ children }) => (
  <Stack alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
    <Typography sx={{ color: theme => theme.colors.functional.text.lint }}>{children}</Typography>
  </Stack>
);
