import { Box, Drawer, IconButton, Popover, Stack, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PERMISSIONS, useAuthContext, User } from 'contexts/AuthContext';
import PromoCodeList from 'pages/admin/ManagePromoCodes/PromoCodeList';
import { TeamSettings } from 'pages/auth/TeamSettings';
import React, { useState } from 'react';
import { FaPowerOff, FaWpforms } from 'react-icons/fa';
import { GrUserSettings } from 'react-icons/gr';
import { ImTicket } from 'react-icons/im';
import { Link, useNavigate } from 'react-router-dom';
import { PATHS } from 'routes';
import { axiosAuth } from 'shared/api';
import AutonomeLogo from 'shared/components/icons/AutonomeLogo';
import IconLink from 'shared/components/icons/IconLink';
import { isAutonomeOrigin } from 'shared/constants/env';
import { useDisclosure } from 'shared/hooks/ui';
import { useBreakpoints } from 'shared/hooks/ui/useBreakpoints';
import { authUtil } from 'shared/utils/auth';

import IconLogo from '../icons/IconLogo';
import HeaderLink from './HeaderLink';

const AppLogo = isAutonomeOrigin ? AutonomeLogo : IconLogo;

const Header: React.FC = () => {
  const { setIsLoggedIn, setLoginSuccessRedirectPath, setUser, user } = useAuthContext();
  const showOrgInfo = user?.org_name && !user?.org_name?.startsWith('user:');
  const navigate = useNavigate();
  const [showSettingsDrawer, setShowSettingsDrawer] = useState(false);
  const {
    onClose: closePromoDrawer,
    onOpen: openPromoDrawer,
    open: isPromoDrawerOpen,
  } = useDisclosure();
  const { md } = useBreakpoints();

  const client = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: async () => {
      await axiosAuth.post('/auth/logout');
    },
    onSuccess: () => {
      setIsLoggedIn(false);
      setUser({} as User);
      client?.clear();
      authUtil.setAccessToken('');
      setLoginSuccessRedirectPath('');
      navigate(PATHS.LOGIN);
    },
  });

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Drawer
        anchor="bottom"
        onClose={() => setShowSettingsDrawer(false)}
        open={showSettingsDrawer}
      >
        <TeamSettings />
      </Drawer>
      <Drawer
        anchor="bottom"
        onClose={closePromoDrawer}
        open={isPromoDrawerOpen}
        sx={{ '.MuiPaper-root': { px: 4, pb: 12, minHeight: '40vh' } }}
      >
        <Typography sx={{ py: 4 }} variant="h3">
          My Promo Codes
        </Typography>
        <PromoCodeList />
      </Drawer>

      <Stack
        component="header"
        direction="row"
        justifyContent="space-between"
        sx={{
          background: theme => theme.colors.functional.container.default,
          borderBottom: theme => `1px solid ${theme.colors.functional.subject.border}`,
        }}
      >
        <Stack px={{ md: 5, sm: 3, xs: 3 }} py={{ md: 3, xs: 2 }}>
          <Link to={PATHS.DEFAULT}>
            <AppLogo
              data-testid="app-logo"
              sx={{ height: { xs: '30px', md: '40px' }, width: 'auto' }}
            />
          </Link>
        </Stack>
        <Stack
          alignItems="center"
          direction="row"
          sx={{ '&>a': { display: { xs: 'none', sm: 'flex' } }, background: 'green' }}
        >
          <HeaderLink
            external
            href={
              isAutonomeOrigin
                ? 'https://docs.altlayer.io/altlayer-documentation/autonome'
                : 'https://docs.altlayer.io/altlayer-documentation/wizard/introduction'
            }
          >
            <Stack alignItems="center" direction="row" justifyContent="center" spacing="8px">
              <Typography variant="bodySmallC">User guide</Typography>
              <IconLink />
            </Stack>
          </HeaderLink>

          {user?.email && (
            <Stack
              alignItems="center"
              direction="row"
              px={{ md: 5, sm: 3, xs: 3 }}
              spacing={2}
              sx={{
                height: '100%',
                background: theme => theme.colors.gradients.sheet,
                '&:hover': {
                  background: theme => theme.colors.gradients.metal,
                },
              }}
            >
              <Box
                alt="profile picture"
                component="img"
                referrerPolicy="no-referrer"
                src={user?.picture}
                sx={{ width: 30, height: 30, borderRadius: '50%' }}
              />
              {md && (
                <Stack>
                  <Typography>{user?.email}</Typography>
                  {showOrgInfo && <Typography fontSize={12}>{user?.org_name}</Typography>}
                </Stack>
              )}

              <IconButton data-testid="toggle-user-menu-btn" onClick={handleClick} size="small">
                <Box
                  component="img"
                  src={`/imgs/chevron_down.svg`}
                  sx={{
                    height: '18px',
                    width: '18px',
                    transform: anchorEl ? 'rotate(180deg)' : 'none',
                    transition: 'all 0.2s ease-in-out',
                  }}
                />
              </IconButton>
              <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                onClose={handleClose}
                open={Boolean(anchorEl)}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Stack
                  sx={{
                    '&>*': {
                      py: 2,
                      px: 3,
                      display: 'flex',
                      gap: 1,
                      cursor: 'pointer',
                      '&:hover': {
                        bgcolor: '#ccc4',
                      },
                    },
                  }}
                >
                  {showOrgInfo && (
                    <Typography
                      onClick={() => {
                        setShowSettingsDrawer(true);
                        handleClose();
                      }}
                    >
                      <GrUserSettings size={20} />
                      Team Settings
                    </Typography>
                  )}
                  <Typography
                    onClick={() => {
                      if (user?.permissions?.includes(PERMISSIONS.WRITE_PROMO_CODE)) {
                        navigate(PATHS.MANAGE_PROMO_CODES);
                      } else {
                        openPromoDrawer();
                      }

                      handleClose();
                    }}
                  >
                    <ImTicket size={20} />
                    {user?.permissions?.includes(PERMISSIONS.WRITE_PROMO_CODE) ? 'Manage ' : 'My '}
                    Promo Codes
                  </Typography>
                  {user?.permissions?.includes(PERMISSIONS.WRITE_PROMO_CODE) && (
                    <Typography
                      onClick={() => {
                        navigate(PATHS.ADMIN);
                        handleClose();
                      }}
                    >
                      <FaWpforms size={20} />
                      Admin Dashboard
                    </Typography>
                  )}

                  <Typography
                    onClick={() => {
                      mutate();
                      handleClose();
                    }}
                  >
                    <FaPowerOff size={20} />
                    Logout
                  </Typography>
                </Stack>
              </Popover>
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default Header;
