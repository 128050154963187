import { DateRangePicker } from 'shared/components/Date';
import { MetricGridItem } from 'shared/components/metric/MetricGridItem';
import { Section } from 'shared/components/Section';

import { DateFilterVariant } from '../../store';
import { AverageBlockSizeMetric } from './AverageBlockSizeMetric';

export const BlocksSection = () => {
  return (
    <Section
      renderGridContent={
        <>
          <MetricGridItem item xs={12}>
            <AverageBlockSizeMetric />
          </MetricGridItem>
        </>
      }
      renderSubHeaderEndContent={
        <DateRangePicker variant={DateFilterVariant.ROLLUP_STATS_BLOCK_SECTION} />
      }
      sectionContentSx={{ p: '0px' }}
      title="Blocks"
    />
  );
};
