import { IconButton, Stack, Typography } from '@mui/material';
import { AccountBalanceSubHeaderChip } from 'pages/raas/DeploymentDetails/components/AccountBalanceSubHeaderChip';
import { isBscSettlementLayer } from 'pages/raas/DeploymentDetails/utils';
import { RollupType } from 'pages/raas/helpers/labels';
import { useRaasDeployment } from 'pages/raas/hooks';
import { useMemo } from 'react';
import { DateRangePicker } from 'shared/components/Date';
import { IconCopy } from 'shared/components/icons/IconCopy';
import { MetricGridItem } from 'shared/components/metric/MetricGridItem';
import { Section } from 'shared/components/Section';
import { useCopyToClipboard } from 'shared/hooks/ui';
import { truncateEthAddress } from 'shared/utils/address';
import { Address } from 'viem';

import { DateFilterVariant } from '../../store';
import { ProposerBalanceHistoryMetric } from './ProposerBalanceHistoryMetric';
import { ProposerTxCountMetric } from './ProposerTxCountMetric';

export const ProposerBalanceSection = () => {
  const [, copy] = useCopyToClipboard();
  const { data: rollup } = useRaasDeployment();
  const proposerAddr: Address = useMemo(
    () => rollup?.accounts?.[rollup?.type === RollupType.OPSTACK ? 'proposer' : 'staker'],
    [rollup?.accounts, rollup?.type],
  );

  return (
    <Section
      renderGridContent={
        <>
          {!isBscSettlementLayer(rollup) && (
            <MetricGridItem item>
              <ProposerBalanceHistoryMetric />
            </MetricGridItem>
          )}
          <MetricGridItem item>
            <ProposerTxCountMetric />
          </MetricGridItem>
        </>
      }
      renderSubHeaderEndContent={
        <DateRangePicker variant={DateFilterVariant.PROPOSER_BALANCE_BALANCE_SECTION} />
      }
      renderTitleSuffix={
        <Stack direction="row" spacing="16px" sx={{ ml: '24px' }}>
          <>
            <AccountBalanceSubHeaderChip account={proposerAddr} />

            {proposerAddr && (
              <Stack alignItems="center" direction="row" justifyContent="center" spacing="8px">
                <Typography sx={{ cursor: 'pointer' }} variant="bodySmall">
                  {truncateEthAddress(proposerAddr)}
                </Typography>
                <IconButton onClick={() => copy(proposerAddr)} size="small">
                  <IconCopy sx={{ height: '20px', width: '20px', cursor: 'pointer' }} />
                </IconButton>
              </Stack>
            )}
          </>
        </Stack>
      }
      sectionContentSx={{ p: 0 }}
      title="Proposer Balance"
    />
  );
};
