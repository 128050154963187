import 'react-day-picker/style.css';

import {
  Box,
  Button,
  GlobalStyles,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Popover,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { CalendarIcon } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { DateFilterVariant } from 'pages/raas/DeploymentDetails/store';
import { useDateRangeStore } from 'pages/raas/DeploymentDetails/store/useDateRangeStore';
import React, { useCallback, useEffect, useState } from 'react';
import { DateRange, DayPicker } from 'react-day-picker';

import { getMuiDayPickerStyles } from './dayPickerStyles';

interface IDateRangePicker {
  variant: DateFilterVariant;
}

const DateRangePicker: React.FC<IDateRangePicker> = ({ variant }) => {
  const { selectedDateRange, setSelectedDateRange } = useDateRangeStore();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const defaultRange = selectedDateRange[variant] || { from: new Date(), to: new Date() };
  const [range, setRange] = useState<DateRange>({
    from: defaultRange.from ?? new Date(),
    to: defaultRange.to ?? new Date(),
  });

  const [selectedQuickOption, setSelectedQuickOption] = useState<string | null>(null);

  const handleApply = useCallback(() => {
    if (range.from && range.to) {
      setSelectedDateRange(variant, { from: range.from, to: range.to });

      handleClose();
    }
  }, [variant, range, setSelectedDateRange]);

  useEffect(() => {
    if (range.from && range.to && range.from.getTime() === range.to.getTime()) {
      const newFrom = new Date(range.from.getTime());
      const newTo = new Date(range.to.getTime());

      newFrom.setHours(0, 0, 0, 0);

      const today = new Date();
      const isToday =
        newFrom.getFullYear() === today.getFullYear() &&
        newFrom.getMonth() === today.getMonth() &&
        newFrom.getDate() === today.getDate();

      if (isToday) {
        newTo.setHours(today.getHours(), 0, 0, 0);
      } else {
        newTo.setHours(23);
      }

      setRange({ from: newFrom, to: newTo });
    }
  }, [range]);

  const handleQuickSelect = (option: string) => {
    const today = new Date();
    let from = new Date();
    let to = new Date();

    switch (option) {
      case 'today':
        from.setHours(0, 0, 0, 0);
        to.setHours(today.getHours(), 0, 0, 0);
        break;
      case 'yesterday':
        from.setDate(today.getDate() - 1);
        to.setDate(today.getDate() - 1);
        break;
      case 'thisWeek':
        from.setDate(today.getDate() - today.getDay());
        to = today;
        break;
      case 'lastWeek':
        from.setDate(today.getDate() - today.getDay() - 7);
        to.setDate(from.getDate() + 6);
        break;
      case 'thisMonth':
        from = new Date(today.getFullYear(), today.getMonth(), 1);
        to = today;
        break;
      case 'lastMonth':
        from = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        to = new Date(today.getFullYear(), today.getMonth(), 0);
        break;
      case 'last7Days':
        from.setDate(today.getDate() - 6);
        to = today;
        break;
      case 'last30Days':
        from.setDate(today.getDate() - 29);
        to = today;
        break;
      default:
        break;
    }

    setSelectedQuickOption(option);

    setRange({ from, to });
  };

  const getButtonLabel = () => {
    return `${dayjs(range.from).format('MM-DD-YYYY')} to ${dayjs(range.to).format('MM-DD-YYYY')}`;
  };

  return (
    <>
      <Button
        endIcon={<CalendarIcon />}
        onClick={handleOpen}
        sx={{ width: '100%', maxWidth: '300px' }}
      >
        <Typography>{getButtonLabel()}</Typography>
      </Button>

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <GlobalStyles styles={getMuiDayPickerStyles} />
          <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
            <Typography sx={{ mb: 2 }} variant="h6">
              {dayjs(range?.from).format('MMM D, YYYY')} - {dayjs(range?.to).format('MMM D, YYYY')}
            </Typography>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <Box sx={{ minWidth: 180 }}>
                <List disablePadding>
                  {[
                    'today',
                    'yesterday',
                    'thisWeek',
                    'lastWeek',
                    'thisMonth',
                    'lastMonth',
                    'last7Days',
                    'last30Days',
                  ].map(option => (
                    <ListItem disablePadding key={option}>
                      <ListItemButton
                        onClick={() => handleQuickSelect(option)}
                        sx={{
                          backgroundColor:
                            selectedQuickOption === option
                              ? theme.colors.functional.container.primary
                              : 'transparent',
                          color:
                            selectedQuickOption === option ? '#FFFFFF' : theme.palette.text.primary,
                          '&:hover': {
                            backgroundColor:
                              selectedQuickOption === option
                                ? theme.colors.functional.container.primary
                                : theme.palette.action.hover,
                            color:
                              selectedQuickOption === option
                                ? '#FFFFFF'
                                : theme.palette.text.primary,
                          },
                        }}
                      >
                        <ListItemText
                          primary={option
                            .replace(/([A-Z])/g, ' $1')
                            .replace(/(\d+)/g, ' $1')
                            .replace(/^./, str => str.toUpperCase())
                            .trim()}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <DayPicker
                  mode="range"
                  onSelect={selectedRange => {
                    setRange({
                      from: selectedRange?.from ?? new Date(),
                      to: selectedRange?.to ?? new Date(),
                    });
                  }}
                  selected={range}
                  showOutsideDays
                />
              </Box>
            </Stack>

            <Stack direction="row" justifyContent="end">
              <Button onClick={handleApply} sx={{ maxWidth: '100px', height: '50px' }}>
                <Typography variant="caption">Apply Changes</Typography>
              </Button>
            </Stack>
          </Paper>
        </LocalizationProvider>
      </Popover>
    </>
  );
};

export default DateRangePicker;
