import { useBlockscoutMetrics } from 'pages/raas/hooks';
import { Metric } from 'shared/components/metric';

import { useDateRangeFilterOptions } from '../../hooks/useDateRangeFilterOptions';
import { DateFilterVariant } from '../../store';

export const TransactionsSuccessRateMetric = () => {
  const {
    end: to,
    start: from,
    timeframe,
  } = useDateRangeFilterOptions(DateFilterVariant.ROLLUP_STATS_TRANSACTIONS_SECTION);

  const { data: result, isPending } = useBlockscoutMetrics({
    variant: 'txnsSuccessRate',
    timeframe,
    from,
    to,
  });

  return (
    <Metric
      data={result}
      description={'Successful transactions rate per day'}
      id={'txns-success-rate'}
      loading={isPending}
      title={'Transactions success rate'}
    />
  );
};
