import {
  Box,
  Button,
  FormControl,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { PaymentStatusChip } from 'pages/admin/ManageClients/PaymentStatusChip';
import { ADMIN_PATHS } from 'pages/admin/routes';
import { debounce } from 'perfect-debounce';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { bff } from 'shared/api';
import { Section } from 'shared/components';
import { FormSelectField } from 'shared/components/form';
import MultilineTruncatedText from 'shared/components/Formatted/MultilineTruncatedText';
import { IconRefresh } from 'shared/components/icons/IconRefresh';
import { TableRowSkeleton } from 'shared/components/Skeleton/TableRowSkeleton';
import { Pagination } from 'shared/components/Table/Pagination';
import TRow from 'shared/components/Table/TRow';
import { useTablePagination } from 'shared/hooks/ui';
import {
  AgreementListReply,
  AgreementListRequest,
  SimpleAgreementInfo,
} from 'shared/types/protoc-gen/bffbillsystem';
import { QUERY_KEYS } from 'shared/types/react-query';
import { ColumnConfig } from 'shared/types/table';

const DEBOUNCE_TIME = 500;

type Status = 'Active' | 'Pause' | 'Terminated' | '';
type FeeStatus = 'Awaiting' | 'Paid' | 'Overdue' | 'PaidAfterOverdue' | '';

interface FormValues {
  clientName: string;
  status: Status;
  serviceFeeStatus: FeeStatus;
  withdrawFeeStatus: FeeStatus;
}

export const ManageClients = () => {
  const navigate = useNavigate();

  const form = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      clientName: '',
      status: '',
      serviceFeeStatus: '',
      withdrawFeeStatus: '',
    },
  });
  const { clientName, serviceFeeStatus, status, withdrawFeeStatus } = form.watch();

  const debouncedClientName = useMemo(
    () =>
      debounce((value: string) => {
        form.setValue('clientName', value);
      }, DEBOUNCE_TIME),
    [form],
  );
  const debouncedStatus = useMemo(
    () => debounce((value: Status) => form.setValue('status', value), DEBOUNCE_TIME),
    [form],
  );
  const debouncedServiceFeeStatus = useMemo(
    () => debounce((value: FeeStatus) => form.setValue('serviceFeeStatus', value), DEBOUNCE_TIME),
    [form],
  );
  const debouncedWithdrawFeeStatus = useMemo(
    () => debounce((value: FeeStatus) => form.setValue('withdrawFeeStatus', value), DEBOUNCE_TIME),
    [form],
  );

  const { data: agreements, isPending: getAgreementsPending } = useQuery({
    queryKey: [
      QUERY_KEYS.ADMIN_CLIENT_LIST,
      { clientName, status, serviceFeeStatus, withdrawFeeStatus },
    ],
    queryFn: async (): Promise<SimpleAgreementInfo[]> => {
      const res = await bff.get<AgreementListReply>('/bff/bill/agreements', {
        params: {
          clientName,
          status,
          serviceFeeStatus,
          withdrawFeeStatus,
        } satisfies Partial<AgreementListRequest>,
      });

      return res.data.agreementInfo;
    },
  });
  const { filteredData, paginationCount, setCurrentPage } = useTablePagination({
    data: agreements ?? [],
  });

  const columnConfig: ColumnConfig<SimpleAgreementInfo>[] = useMemo(
    () => [
      {
        id: 'clientName',
        Header: 'Client',
        Cell: agreement => <Typography variant="bodySmallM">{agreement?.clientName}</Typography>,
      },
      {
        id: 'paymentFrequency',
        Header: 'Payment period',
        Cell: agreement => (
          <Typography variant="bodySmall">{agreement?.paymentFrequency}</Typography>
        ),
      },
      {
        id: 'serviceFeeBalance',
        Header: 'Service fee balance',
        Cell: agreement => (
          <Typography variant="bodySmall">{agreement?.serviceFeeBalance}</Typography>
        ),
      },
      {
        id: 'agreementStatus',
        Header: 'Agreement status',
        Cell: agreement =>
          agreement?.agreementStatus && <PaymentStatusChip status={agreement?.agreementStatus} />,
      },
      {
        id: 'otherFeeStatus',
        Header: 'Other fee status',
        Cell: agreement =>
          agreement?.otherFeeStatus && <PaymentStatusChip status={agreement?.otherFeeStatus} />,
      },
      {
        id: 'serviceFeeStatus',
        Header: 'Service fee status',
        Cell: agreement => (
          <Stack alignItems="center" direction="row" flexWrap="wrap" spacing={{ xs: 0, xl: 1 }}>
            {agreement?.serviceFeeNextPayment && (
              <Typography variant="bodySmall">
                {dayjs(agreement?.serviceFeeNextPayment).format('MM/DD/YYYY')}
              </Typography>
            )}
            {agreement?.serviceFeeStatus && (
              <PaymentStatusChip status={agreement?.serviceFeeStatus} />
            )}
          </Stack>
        ),
      },
      {
        id: 'withdrawFeeStatus',
        Header: 'Withdraw fee status',
        Cell: agreement => (
          <Stack alignItems="center" direction="row" flexWrap="wrap" spacing={{ xs: 0, xl: 1 }}>
            {agreement?.withdrawFeeNextPayment && (
              <Typography variant="bodySmall">
                {dayjs(agreement?.withdrawFeeNextPayment).format('MM/DD/YYYY')}
              </Typography>
            )}
            {agreement?.withdrawFeeStatus && (
              <PaymentStatusChip status={agreement?.withdrawFeeStatus} />
            )}
          </Stack>
        ),
      },
      {
        id: 'notes',
        Header: 'Notes',
        Cell: agreement => (
          <MultilineTruncatedText maxLines={4} text={agreement?.notes} variant="caption" />
        ),
        sx: { width: '20%' },
      },
      {
        id: 'action',
        Header: 'Details',
        Cell: agreement => (
          <Button
            id={`viewClientButton_clientName_${agreement?.clientName}`}
            onClick={() =>
              navigate(
                ADMIN_PATHS.MANAGE_CLIENTS_DETAILS.replace(
                  ':clientId',
                  agreement?.agreementId?.toString(),
                ),
              )
            }
            size="small"
            variant="contained"
          >
            View
          </Button>
        ),
      },
    ],
    [navigate],
  );

  return (
    <Section
      renderSubHeaderEndContent={
        <Stack>
          <Button
            id="addClientButton"
            onClick={() => navigate(ADMIN_PATHS.MANAGE_CLIENTS_CREATE)}
            size="small"
          >
            Add
          </Button>
        </Stack>
      }
      title="Manage Clients"
    >
      <TableContainer>
        <FormProvider {...form}>
          <Box
            component="form"
            onSubmit={form.handleSubmit(() => form.reset())}
            sx={{
              '.MuiSelect-select': {
                border: 0,
                p: 3,
              },
              '.MuiFormLabel-root': {
                top: '9px',
              },
            }}
          >
            <Grid columns={14} container>
              <Grid item md={3} xs={14}>
                <FormControl data-testid="clientName_formControl" fullWidth>
                  <TextField
                    fullWidth
                    onChange={event => debouncedClientName(event.target.value ?? '')}
                    placeholder="Search by client name"
                  />
                </FormControl>
              </Grid>
              <Grid item md={3} xs={14}>
                <FormSelectField
                  fieldConfig={{ name: 'status' }}
                  inputLabel="Status"
                  items={[
                    { label: 'Active', value: 'Active' },
                    { label: 'Pause', value: 'Pause' },
                    { label: 'Terminated', value: 'Terminated' },
                  ]}
                  onChange={event => debouncedStatus(event.target.value as Status)}
                />
              </Grid>
              <Grid item md={3} xs={14}>
                <FormSelectField
                  fieldConfig={{ name: 'serviceFeeStatus' }}
                  inputLabel="Service fee status"
                  items={[
                    { label: 'Awaiting', value: 'Awaiting' },
                    { label: 'Paid', value: 'Paid' },
                    { label: 'Overdue', value: 'Overdue' },
                    { label: 'Paid after overdue', value: 'PaidAfterOverdue' },
                  ]}
                  onChange={event => debouncedServiceFeeStatus(event.target.value as FeeStatus)}
                />
              </Grid>
              <Grid item md={3} xs={14}>
                <FormSelectField
                  fieldConfig={{ name: 'withdrawFeeStatus' }}
                  inputLabel="Withdraw fee status"
                  items={[
                    { label: 'Awaiting', value: 'Awaiting' },
                    { label: 'Paid', value: 'Paid' },
                    { label: 'Overdue', value: 'Overdue' },
                    { label: 'Paid after overdue', value: 'PaidAfterOverdue' },
                  ]}
                  onChange={event => debouncedWithdrawFeeStatus(event.target.value as FeeStatus)}
                />
              </Grid>
              <Grid item md={2} xs={14}>
                <Button
                  sx={{ minHeight: '64px', height: '100%', width: '100%' }}
                  type="submit"
                  variant="sheet"
                >
                  <Stack alignItems="center" direction="row" spacing={2}>
                    <IconRefresh />
                    <Typography fontSize={18} variant="bodySmallM">
                      Reset
                    </Typography>
                  </Stack>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </FormProvider>
        <Table sx={{ tableLayout: { xs: 'auto', lg: 'fixed' }, width: '100%' }}>
          <TableHead>
            <TableRow>
              {columnConfig.map(({ Header, align, id, sortable, width }) => (
                <TableCell align={align} key={id} sx={{ width }}>
                  <Stack
                    alignItems="center"
                    direction="row"
                    sx={{ cursor: sortable ? 'pointer' : 'auto', display: 'inline-flex' }}
                  >
                    <Typography variant="captionC">{Header}</Typography>
                  </Stack>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {getAgreementsPending ? (
              Array.from({ length: 10 }).map((_, index) => (
                <TableRowSkeleton cellCount={9} key={index} />
              ))
            ) : filteredData?.length > 0 ? (
              filteredData?.map(agreement => (
                <TRow<SimpleAgreementInfo>
                  columnConfig={columnConfig}
                  data-testid={`tableRow_${agreement?.clientName}`}
                  key={agreement?.agreementId}
                  rowData={agreement}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9}>
                  <Typography variant="bodySmall">No client records</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <Pagination
            paginationOptions={{
              count: paginationCount,
              onChange: (_, page) => setCurrentPage(page),
            }}
            sx={{ pl: 5, py: 3 }}
          />
        </Table>
      </TableContainer>
    </Section>
  );
};
