import { TableCell, TableRow, TableRowProps, Typography } from '@mui/material';
import { ColumnConfig } from 'shared/types/table';

interface TRowProps<TRowData> extends TableRowProps {
  columnConfig: ColumnConfig<TRowData>[];
  rowData: TRowData;
}

function TRow<TRowData>({ columnConfig, rowData, sx, ...rest }: TRowProps<TRowData>) {
  return (
    <TableRow hover sx={sx} {...rest}>
      {columnConfig?.map(({ Cell, align, cellProps, id, sx, width }) => (
        <TableCell align={align} key={String(id)} sx={{ width, ...sx }} {...cellProps}>
          {typeof Cell(rowData) === 'string' ? (
            <Typography gutterBottom noWrap variant="body1">
              {Cell(rowData)}
            </Typography>
          ) : (
            Cell(rowData)
          )}
        </TableCell>
      ))}
    </TableRow>
  );
}

export default TRow;
