import { Grid } from '@mui/material';
import { BridgeTVLMetric } from 'pages/raas/DeploymentDetails/views/OverviewView/BridgeTVLMetric';
import { DateRangePicker } from 'shared/components/Date';
import { MetricGridItem } from 'shared/components/metric/MetricGridItem';
import { Section } from 'shared/components/Section';

import { DateFilterVariant } from '../../store';
import { TokenPairInfoMetric } from './TokenPairInfoMetric';

export const GeneralSection = () => {
  return (
    <Section
      renderGridContent={
        <>
          <MetricGridItem item>
            <BridgeTVLMetric />
          </MetricGridItem>
          <Grid item xs={6}>
            <TokenPairInfoMetric />
          </Grid>
        </>
      }
      renderSubHeaderEndContent={
        <DateRangePicker variant={DateFilterVariant.BRIDGE_METRICS_GENERAL_SECTION} />
      }
      sectionContentSx={{ p: '0px' }}
      title="General"
    />
  );
};
