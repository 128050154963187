import { Box, Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useCallback } from 'react';
import { FieldPath, FormProvider, useFormContext } from 'react-hook-form';
import {
  FormNumericTextField,
  FormSelectField,
  FormSelectItem,
  FormTextFieldBase,
  Label,
} from 'shared/components/form';
import { FormGroupContainer, IFormGroupContainer } from 'shared/components/form/form-group';
import { IconCircleMinus } from 'shared/components/icons/IconCircleMinus';
import { IconCopy } from 'shared/components/icons/IconCopy';
import { useCopyToClipboard } from 'shared/hooks/ui';

import { AgreementInfoFormValues } from './types';

interface IAgreementInfoFormGroup extends Omit<IFormGroupContainer, 'title'> {
  writeMode: boolean;
}

export const AgreementInfoFormGroup = ({ writeMode = true, ...props }: IAgreementInfoFormGroup) => {
  const form = useFormContext<AgreementInfoFormValues>();
  const formValues = form.watch();
  const [, copy] = useCopyToClipboard();

  const renderCopyIconButton = useCallback(
    (field: FieldPath<AgreementInfoFormValues>) => {
      return (
        <IconButton onClick={() => copy(String(form.getValues(field)))}>
          <IconCopy />
        </IconButton>
      );
    },
    [copy, form],
  );

  const addAgreementLink = useCallback(() => {
    form.setValue('agreementLinks', [...(formValues.agreementLinks || []), '']);
  }, [form, formValues.agreementLinks]);

  const removeAgreementLink = useCallback(
    (index: number) => {
      form.setValue(
        'agreementLinks',
        formValues.agreementLinks?.filter((_, i) => i !== index) || [],
      );
    },
    [form, formValues.agreementLinks],
  );

  return (
    <FormProvider {...form}>
      <FormGroupContainer
        data-testid="agreement-info-form-group-container"
        sx={{
          '.MuiInputBase-root.Mui-disabled': {
            background: '#fff',
            borderRadius: 0,
          },
        }}
        title="Agreement Information"
        {...props}
      >
        <Box>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Label id="agreementStatus_label" label="Agreement status" sx={{ pb: 3 }}>
                <FormSelectField<FormSelectItem>
                  disabled={!writeMode}
                  fieldConfig={{ name: 'agreementStatus', placeholder: 'Select agreement status' }}
                  id="agreementStatus_field"
                  items={[
                    { label: 'Active', value: 'Active' },
                    { label: 'Pause', value: 'Pause' },
                    { label: 'Terminated', value: 'Terminated' },
                  ]}
                  placeholder="Select agreement status"
                />
              </Label>
            </Grid>
            <Grid item md={6} xs={12}>
              <Label id="agreementScope_label" label="Agreement scope" sx={{ pb: 3 }}>
                <FormSelectField<FormSelectItem>
                  disabled={!writeMode}
                  fieldConfig={{ name: 'agreementScope', placeholder: 'Select agreement scope' }}
                  id="agreementScope_field"
                  items={[
                    { label: 'Testnet', value: 1 },
                    { label: 'Mainnet', value: 2 },
                    { label: 'Testnet & Mainnet', value: 3 },
                  ]}
                />
              </Label>
            </Grid>
            <Grid item md={6} xs={12}>
              <Label id="paymentFrequency_label" label="Payment frequency" sx={{ pb: 3 }}>
                <FormSelectField<FormSelectItem>
                  disabled={!writeMode}
                  fieldConfig={{
                    name: 'paymentFrequency',
                    placeholder: 'Select payment frequency',
                  }}
                  id="paymentFrequency_field"
                  items={[
                    { label: 'Monthly', value: 'Monthly' },
                    { label: 'Quarterly', value: 'Quarterly' },
                    { label: 'Yearly', value: 'Yearly' },
                  ]}
                  placeholder="Select payment frequency"
                />
              </Label>
            </Grid>
            <Grid item md={6} xs={12}>
              <Label id="withdrawFeePercentage_label" label="Withdraw fee percentage">
                <FormNumericTextField<AgreementInfoFormValues>
                  InputProps={{
                    endAdornment: !writeMode ? (
                      renderCopyIconButton('withdrawFeePercentage')
                    ) : (
                      <Typography>%</Typography>
                    ),
                  }}
                  disabled={!writeMode}
                  fieldConfig={{ name: 'withdrawFeePercentage' }}
                  helperText="Enter a value between 0 and 100 (e.g. 5 for 5%)"
                  hideLabel
                  id="withdrawFeePercentage_field"
                  placeholder="Enter withdraw fee"
                />
              </Label>
            </Grid>
            <Grid item xs={12}>
              <Label id="amount_label" label="Amount per payment">
                <FormNumericTextField<AgreementInfoFormValues>
                  InputProps={
                    !writeMode
                      ? {
                          endAdornment: renderCopyIconButton('amount'),
                        }
                      : {
                          endAdornment: <Typography>USDC</Typography>,
                        }
                  }
                  disabled={!writeMode}
                  fieldConfig={{ name: 'amount' }}
                  hideLabel
                  id="amount_field"
                  placeholder="Enter amount per payment"
                />
              </Label>
            </Grid>
            <Grid item xs={12}>
              <Label
                id="agreementLinks_label"
                label="Agreement links"
                labelEndAdornment={
                  <Button
                    disabled={!writeMode}
                    onClick={addAgreementLink}
                    size="small"
                    variant="outlined"
                  >
                    <Typography>Add Item</Typography>
                  </Button>
                }
                sx={{ pb: 3 }}
              >
                <Stack spacing={1} width="100%">
                  {formValues.agreementLinks?.map((agreementLink, index) => (
                    <FormTextFieldBase<AgreementInfoFormValues>
                      InputProps={{
                        endAdornment: !writeMode ? (
                          renderCopyIconButton(`agreementLinks.${index}`)
                        ) : (
                          <IconButton onClick={() => removeAgreementLink(index)} size="small">
                            <IconCircleMinus />
                          </IconButton>
                        ),
                      }}
                      disabled={!writeMode}
                      fieldConfig={{ name: `agreementLinks.${index}` }}
                      helperTextShrink={true}
                      hiddenLabel
                      id={`agreementLinks_field_${index}`}
                      key={`agreementLinks.${index}`}
                      placeholder={`${index + 1}. Enter agreement link`}
                      value={agreementLink}
                    />
                  ))}
                </Stack>
              </Label>
            </Grid>
            <Grid item xs={12}>
              <Label id="notes_label" label="Notes">
                <FormTextFieldBase<AgreementInfoFormValues>
                  InputProps={
                    !writeMode
                      ? {
                          endAdornment: renderCopyIconButton('notes'),
                        }
                      : undefined
                  }
                  disabled={!writeMode}
                  fieldConfig={{ name: 'notes' }}
                  id="notes_field"
                  minRows={4}
                  multiline
                  placeholder="Enter notes"
                  sx={{ textarea: { px: 1 } }}
                />
              </Label>
            </Grid>
          </Grid>
        </Box>
      </FormGroupContainer>
    </FormProvider>
  );
};
