import { LoadingButton } from '@mui/lab';
import { Menu, MenuItem, Stack, Typography } from '@mui/material';
import { useRpcInstances } from 'pages/raas/hooks';
import { useEffect, useState } from 'react';
import { AnimatedIconChevronDown } from 'shared/components/icons/AnimatedIconChevronDown';
import { useMenuDisclosure } from 'shared/hooks/ui/useMenuDisclosure';

import RpcCountSection from './RpcCountSection';
import { RpmByMethodsSection } from './RpmByMethodsSection';
import RpsByMethodSection from './RpsByMethodSection';
import RpsMetricSection from './RpsMetricSection';

export interface IRpcMonitoringSections {
  instance: string;
}

export const RpcUsageMonitoringView = () => {
  const [selectedInstance, setSelectedInstance] = useState<string>('');

  const { data: rpcInstancesData, isLoading: loadingFetchInstances } = useRpcInstances();

  const {
    anchorEl: methodsMenuAnchorEl,
    onClose: onCloseMethodsMenu,
    onOpen: onOpenMethodsMenu,
    open: openedMethodsMenu,
  } = useMenuDisclosure();

  useEffect(() => {
    if (!selectedInstance && rpcInstancesData && rpcInstancesData.length > 0) {
      setSelectedInstance(rpcInstancesData[0]);
    }
  }, [rpcInstancesData, selectedInstance]);

  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        sx={{
          backgroundColor: theme => theme.colors.gradients.metal,
          p: '16px 40px',
          borderBottom: theme => `1px solid ${theme.colors.functional.subject.border}`,
          borderTop: theme => `1px solid ${theme.colors.functional.subject.border}`,
          width: '100%',
        }}
      >
        <Typography variant="bodySmallC">Instance:</Typography>
        <LoadingButton
          loading={loadingFetchInstances}
          onClick={onOpenMethodsMenu}
          sx={theme => ({
            backgroundColor: 'transparent !important',
            '&, &:hover': {
              background: '#fff',
              color: theme.colors.functional.text.primary,
              p: 0,
            },
          })}
        >
          <Stack direction="row" spacing="32px" sx={{ p: '16px' }}>
            <Typography variant="bodySmallM">{selectedInstance}</Typography>
            <AnimatedIconChevronDown open={openedMethodsMenu} />
          </Stack>
        </LoadingButton>
        <Menu anchorEl={methodsMenuAnchorEl} onClose={onCloseMethodsMenu} open={openedMethodsMenu}>
          {rpcInstancesData?.map(instance => {
            return (
              <MenuItem
                key={instance}
                onClick={() => {
                  setSelectedInstance(instance);
                  onCloseMethodsMenu();
                }}
              >
                {instance}
              </MenuItem>
            );
          })}
        </Menu>
      </Stack>
      <RpcCountSection instance={selectedInstance} />
      <RpsMetricSection instance={selectedInstance} />
      <RpsByMethodSection instance={selectedInstance} />
      <RpmByMethodsSection instance={selectedInstance} />
    </>
  );
};
