export enum QUERY_KEYS {
  GET_APP_TOKENS = 'GET_APP_TOKENS',
  GET_BFF_PROMO_CODES = 'GET_BFF_PROMO_CODES',
  GET_AVS_PROMO_CODES = 'GET_AVS_PROMO_CODES',
  VERIFY_PROMO_CODE = 'VERIFY_PROMO_CODE',
  VERIFY_FREE_TRIAL_PROMO_CODE = 'VERIFY_FREE_TRIAL_PROMO_CODE',
  VERIFY_SUBSCRIPTION_PROMO_CODE = 'VERIFY_SUBSCRIPTION_PROMO_CODE',
  GET_DEPLOYMENTS = 'GET_ROLLUP_DEPLOYMENTS',
  GET_DEPLOYMENT = 'GET_ROLLUP_DEPLOYMENT',
  GET_AVS_DEPLOYMENTS = 'GET_AVS_DEPLOYMENTS',
  GET_AGGREGATORS_STATUS = 'GET_AGGREGATORS_STATUS',
  GET_ROLLUP_KEYS = 'GET_ROLLUP_KEYS',
  CREATE_AVS = 'CREATE_AVS',
  GET_AVS_OPERATORS = 'GET_AVS_OPERATORS',
  GET_AVS_METADATA = 'GET_AVS_METADATA',
  GET_OPERATOR_STATUS = 'GET_OPERATOR_STATUS',
  GET_GLOBAL_AVS = 'GET_GLOBAL_AVS',
  GET_AAA_DEPLOYED_APPS = 'GET_AAA_DEPLOYED_APPS',
  PREVIEW_METADATA = 'PREVIEW_METADATA',
  GET_RAAS_METRICS = 'GET_RAAS_METRICS',
  GET_RAAS_RPC_INSTANCES = 'GET_RAAS_RPC_INSTANCES',
  GET_BLOCKSCOUT_STATS = 'GET_BLOCKSCOUT_STATS',
  VIEM_BATCH_BALANCES = 'VIEM_BATCH_BALANCES',
  GET_ROLLUP_STATUS = 'GET_ROLLUP_STATUS',
  AAAS_GET_AAA_APPS = 'AAAS_GET_AAA_APPS',
  AAAS_GET_AAA_TEMPLATES = 'AAAS_GET_AAA_TEMPLATES',
  AAAS_GET_FEATURED_TEMPLATES = 'AAAS_GET_FEATURED_TEMPLATES',
  AAAS_HEALTHZ = 'AAAS_HEALTHZ',
  AAAS_PENDING_AGENT_REQUESTS = 'AAAS_PENDING_AGENT_REQUESTS',
  AAAS_GET_AAA_APP_LOGS = 'AAAS_GET_AAA_APP_LOGS',
  AAAS_GET_AAA_CHAT_ENDPOINT = 'AAAS_GET_AAA_CHAT_ENDPOINT',
  AAAS_GET_APP_AGENTS = 'AAAS_GET_APP_AGENTS',
  AAAS_GET_ELIZA_CONFIG = 'AAAS_GET_ELIZA_CONFIG',
  AAAS_GET_GENERATED_CHARACTER = 'AAAS_GET_GENERATED_CHARACTER',
  AAAS_LIST_GENERATED_CHARACTERS = 'AAAS_LIST_GENERATED_CHARACTERS',
  RAAS_WITHDRAW_FEE_STATUS = 'RAAS_WITHDRAW_FEE_STATUS',
  RAAS_WITHDRAW_FEE_HISTORY = 'RAAS_WITHDRAW_FEE_HISTORY',
  RAAS_WITHDRAW_FEE_BILLS = 'RAAS_WITHDRAW_FEE_BILLS',
  DNS_LOOKUP = 'DNS_LOOKUP',
  RAAS_BALANCE_ALERT_LIST_ACCOUNTS = 'RAAS_BALANCE_ALERT_LIST_ACCOUNTS',
  RAAS_WITHDRAW_FEE_REGISTERED = 'RAAS_WITHDRAW_FEE_REGISTERED',
  BFF_PRODUCT_PAYMENT_LINKS = 'BFF_PRODUCT_PAYMENT_LINKS',
  BFF_USER_SUBSCRIPTIONS = 'BFF_USER_SUBSCRIPTIONS',
  WEB3_FETCH_BATCH_TOKEN_BALANCES = 'WEB3_FETCH_BATCH_TOKEN_BALANCES',
  ADMIN_CLIENT_LIST = 'ADMIN_CLIENT_LIST',
  ADMIN_CLIENT_DETAILS = 'ADMIN_CLIENT_DETAILS',
  ADMIN_CLIENT_PAYMENT_LIST = 'ADMIN_CLIENT_PAYMENT_LIST',
  ADMIN_CLIENT_PAYMENT_TRACKER = 'ADMIN_CLIENT_PAYMENT_TRACKER',
  ADMIN_CLIENT_PAYMENT_DETAILS = 'ADMIN_CLIENT_PAYMENT_DETAILS',
  ADMIN_CLIENT_COST_LIST = 'ADMIN_CLIENT_COST_LIST',
  ADMIN_CLIENT_COST_INFO = 'ADMIN_CLIENT_COST_INFO',
}

export enum MUTATION_KEYS {
  AAAS_DEPLOY_APPS = 'AAAS_DEPLOY_APPS',
  AAAS_VERIFY_PROMO_CODE = 'AAAS_VERIFY_PROMO_CODE',
  AAAS_AI_AGENT_PROMPT = 'AAAS_AI_AGENT_PROMPT',
  AAAS_GENERATE_PERSONA = 'AAAS_GENERATE_PERSONA',
  PROMOTE_TEAM_MEMBER = 'PROMOTE_TEAM_MEMBER',
  KICK_TEAM_MEMBER = 'KICK_TEAM_MEMBER',
  ADD_NETWORK_TO_WALLET = 'ADD_NETWORK_TO_WALLET',
  AAAS_CREATE_AAA_APP = 'AAAS_CREATE_AAA_APP',
  AAAS_TERMINATE_AAA_APP = 'AAAS_TERMINATE_AAA_APP',
  AAAS_RESTART_AAA_APP = 'AAAS_RESTART_AAA_APP',
  AAAS_UPDATE_AAA_APP_CONFIG = 'AAAS_UPDATE_AAA_APP_CONFIG',
  RAAS_WITHDRAW_FEE_REGISTER = 'RAAS_WITHDRAW_FEE_REGISTER',
  RAAS_WITHDRAW_FEE_TRIGGER = 'RAAS_WITHDRAW_FEE_TRIGGER',
  RAAS_BALANCE_ALERT_DELETE_ACCOUNT = 'RAAS_BALANCE_ALERT_DELETE_ACCOUNT',
  RAAS_BALANCE_ALERT_CREATE_ACCOUNT = 'RAAS_BALANCE_ALERT_CREATE_ACCOUNT',
  RAAS_BALANCE_ALERT_UPDATE_ACCOUNT = 'RAAS_BALANCE_ALERT_UPDATE_ACCOUNT',
  BFF_PAYMENT_FULFILLMENT = 'BFF_PAYMENT_FULFILLMENT',
  ADMIN_CLIENT_CREATE_AGREEMENT = 'ADMIN_CLIENT_CREATE_AGREEMENT',
  ADMIN_CLIENT_UPDATE_BASIC_INFO = 'ADMIN_CLIENT_UPDATE_BASIC_INFO',
  ADMIN_CLIENT_UPDATE_AGREEMENT_INFO = 'ADMIN_CLIENT_UPDATE_AGREEMENT_INFO',
  ADMIN_CLIENT_CREATE_PAYMENT = 'ADMIN_CLIENT_CREATE_PAYMENT',
  ADMIN_CLIENT_UPDATE_PAYMENT = 'ADMIN_CLIENT_UPDATE_PAYMENT',
  ADMIN_CLIENT_COST_DELETE = 'ADMIN_CLIENT_COST_DELETE',
  ADMIN_CLIENT_COST_CREATE = 'ADMIN_CLIENT_COST_CREATE',
  ADMIN_CLIENT_COST_UPDATE = 'ADMIN_CLIENT_COST_UPDATE',
}
