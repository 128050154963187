import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { showExperimentalFeatures } from 'pages/raas/DeploymentDetails/helpers/visibility';
import { getTokenSymbol } from 'pages/raas/DeploymentDetails/utils';
import { useRaasDeployment } from 'pages/raas/hooks';
import { useCallback, useMemo } from 'react';
import { Section } from 'shared/components';
import IconInfo from 'shared/components/icons/IconInfo';
import { TableRowSkeleton } from 'shared/components/Skeleton/TableRowSkeleton';
import TRow from 'shared/components/Table/TRow';
import { WithdrawBill } from 'shared/types/protoc-gen/bffbillsystem';
import { ColumnConfig } from 'shared/types/table';
import { countLeadingZeroes } from 'shared/utils/number';
import { commify, formatWithPrecision } from 'shared/utils/strings';

import { WithdrawHistorySection } from '../../AutoWithdrawalView/components';
import { useWFBills } from '../hooks';

export const CollectionHistorySection: React.FC = () => {
  const { data: rollup } = useRaasDeployment();

  const { data: monthlyBills, isPending } = useWFBills({
    params: { rollupId: rollup?.id ?? '' },
    enabled: Boolean(rollup?.id),
  });

  const formatEthNumber = useCallback(
    (value?: number) => {
      if (value === undefined || isNaN(value)) return '';

      return `${commify(
        formatWithPrecision(value, countLeadingZeroes(value) >= 6 ? 2 : 4),
      )} ${getTokenSymbol(rollup)}`;
    },
    [rollup],
  );

  const columnConfig: ColumnConfig<WithdrawBill>[] = useMemo(
    () => [
      {
        id: 'date',
        Header: 'DATE',
        Cell: row => {
          const formattedMonthDate = String(row.billMonth).replace(/(\d{4})(\d{2})/, '$1/$2');

          return <Typography variant="bodySmall">{formattedMonthDate}</Typography>;
        },
      },
      {
        id: 'totalFee',
        Header: 'MONTHLY TOTAL FEE',
        description: 'Fees collected by the 3 fee vaults during this month',
        Cell: row => (
          <Typography variant="bodySmallM">{formatEthNumber(Number(row.totalFee))}</Typography>
        ),
      },
      {
        id: 'monthlyBaseFee',
        Header: 'MONTHLY BASE FEE',
        Cell: row => (
          <Typography variant="bodySmallM">{formatEthNumber(Number(row.totalBaseFee))}</Typography>
        ),
      },
      {
        id: 'monthlyL1Fee',
        Header: 'MONTHLY L1 FEE',
        Cell: row => (
          <Typography variant="bodySmallM">{formatEthNumber(Number(row.totalL1Fee))}</Typography>
        ),
      },
      {
        id: 'monthlySequencerFee',
        Header: 'MONTHLY SEQUENCER FEE',
        Cell: row => (
          <Typography variant="bodySmallM">
            {formatEthNumber(Number(row.totalSequencerFee))}
          </Typography>
        ),
      },
      ...(showExperimentalFeatures
        ? ([
            {
              id: 'altlayerFee',
              Header: 'ALTLAYER FEE',
              description:
                'AltLayer will charge the proportion of the fee that agreed in the contract',
              Cell: row => (
                <Typography variant="bodySmallM">
                  {formatEthNumber(Number(row.altlayerFee))}
                </Typography>
              ),
            },
            {
              id: 'opFee',
              Header: 'OP FEE',
              description: 'OP will charge a specific proportion of the fee which is 15%.',
              Cell: row => (
                <Typography variant="bodySmallM">{formatEthNumber(Number(row.opFee))}</Typography>
              ),
            },
            {
              id: 'gasFee',
              Header: 'GAS FEE',
              description: 'The monthly fee paid by AltLayer when processing the transactions.',
              Cell: row => (
                <Typography variant="bodySmallM">{formatEthNumber(Number(row.gasFee))}</Typography>
              ),
            },
            {
              id: 'bill',
              Header: 'EARNINGS',
              description: 'Earnings = Total fee - AltLayer fee - OpFee - Gas fee',
              Cell: row => (
                <Typography variant="bodySmallM">
                  {formatEthNumber(Number(row.earnings))}
                </Typography>
              ),
            },
          ] as ColumnConfig<WithdrawBill>[])
        : []),
    ],
    [formatEthNumber],
  );

  return (
    <>
      <WithdrawHistorySection />
      <Section title="Monthly Bill">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columnConfig.map(config => (
                  <TableCell key={config.id}>
                    <Stack alignItems="center" direction="row" spacing={1}>
                      <Typography variant="captionC">{config.Header}</Typography>

                      {!!config.description && (
                        <Tooltip placement="top" title={config.description}>
                          <Box component="span" sx={{ pt: '4px' }}>
                            <IconInfo sx={{ height: '15px', width: '15px' }} />
                          </Box>
                        </Tooltip>
                      )}
                    </Stack>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isPending ? (
                Array.from({ length: 10 }).map((_, index) => <TableRowSkeleton key={index} />)
              ) : monthlyBills && monthlyBills.length > 0 ? (
                monthlyBills.map((data, index) => (
                  <TRow<WithdrawBill>
                    columnConfig={columnConfig}
                    key={`${data?.altlayerFee}-${index}`}
                    rowData={data}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Typography>No data.</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Section>
    </>
  );
};
