import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { MarkdownRenderer } from 'shared/components/MarkdownRenderer';

import faqMarkdown from './faq-markdown';

export const FaqDialog: FC<DialogProps> = ({ onClose, ...props }) => {
  return (
    <Dialog onClose={onClose} {...props}>
      <DialogTitle sx={{ px: 5, py: 3 }}>
        <Typography sx={{ fontSize: { xs: '18px', sm: '24px' } }} variant="h5">
          Frequently Asked Questions
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ px: 5, py: 3 }}>
        <MarkdownRenderer markdown={faqMarkdown} />
      </DialogContent>
      <DialogActions sx={{ px: 5, py: 3 }}>
        <Button fullWidth onClick={() => onClose?.({}, 'backdropClick')}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
