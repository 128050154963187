import { useRaasMetrics } from 'pages/raas/hooks';
import { useParams } from 'react-router-dom';
import { Metric } from 'shared/components/metric';
import { Panel } from 'shared/types/protoc-gen/raas-metrics';

import { useDateRangeFilterOptions } from '../../hooks/useDateRangeFilterOptions';
import { useYAxisMinValue } from '../../hooks/useYAxisMinValue';
import { DateFilterVariant } from '../../store';

export const L2UnsafeMetric = () => {
  const { rollupId } = useParams();

  const { end, start, step, timeframe } = useDateRangeFilterOptions(
    DateFilterVariant.L2_NODES_SECTION,
  );

  const { data: result, isPending } = useRaasMetrics({
    panel: Panel.PANEL_OPSTACK_L2_UNSAFE,
    timeframe,
    rollupId,
    start,
    end,
    step,
  });

  const { data: yAxisMinValue = 0 } = useYAxisMinValue({
    panel: Panel.PANEL_OPSTACK_L2_UNSAFE,
    timeframe,
    result,
    rollupId,
  });

  return (
    <Metric
      chartType="line"
      curveType="natural"
      data={result}
      description={'Block height of L2 unsafe'}
      id={'l2-unsafe'}
      legendDirection={'column'}
      legendSpacing={'4px'}
      loading={isPending}
      title={'L2 unsafe'}
      yAxisProps={{
        domain: [yAxisMinValue],
      }}
    />
  );
};
