import dayjs from 'dayjs';
import { Timeframe } from 'pages/raas/hooks';
import { useMemo } from 'react';

export const useTimeFilterOptions = (
  timeframe?: Timeframe,
): {
  start: string;
  end: string;
  step: '1h' | '10m' | '1d' | '24h' | '48h';
  timeframe?: Timeframe;
} =>
  useMemo(() => {
    return {
      start: getStartTime(timeframe),
      end: getEndTime(timeframe),
      step: getStep(timeframe),
      timeframe,
    };
  }, [timeframe]);

const getStartTime = (timeframe?: Timeframe) => {
  const now = dayjs();

  switch (timeframe) {
    case 'month':
      return now.subtract(30, 'days').startOf('day').format('YYYY-MM-DD');
    case 'week':
      return now.subtract(7, 'days').startOf('day').format('YYYY-MM-DD');
    case 'day':
      return now.subtract(1, 'days').startOf('day').format('YYYY-MM-DD');
    case 'hour':
      return now.subtract(1, 'hours').startOf('hour').format('YYYY-MM-DD HH:00');
    default:
      return now.subtract(7, 'days').startOf('day').format('YYYY-MM-DD');
  }
};

const getEndTime = (timeframe?: Timeframe) => {
  const now = dayjs();

  switch (timeframe) {
    case 'month':
      return now.endOf('day').format('YYYY-MM-DD');
    case 'week':
      return now.endOf('week').format('YYYY-MM-DD');
    case 'day':
      return now.endOf('day').format('YYYY-MM-DD');
    case 'hour':
      return now.endOf('hour').format('YYYY-MM-DD HH:00');
    default:
      return now.endOf('day').format('YYYY-MM-DD');
  }
};

const getStep = (timeframe?: Timeframe) => {
  switch (timeframe) {
    case 'month':
      return '24h';
    case 'week':
      return '24h';
    case 'day':
      return '1h';
    case 'hour':
      return '10m';
    default:
      return '24h';
  }
};
