import { StandardTextFieldProps, TextField as MuiTextField } from '@mui/material';
import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form';
import { FormFieldConfig } from 'shared/components/form/fields/types';

export interface IFormTextFieldBase<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends StandardTextFieldProps {
  fieldConfig: FormFieldConfig<TFieldValues, TName>;
  controllerProps?: Partial<ControllerProps<TFieldValues, TName>>;
  helperTextShrink?: boolean;
}

export const FormTextFieldBase = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  component: TextField = MuiTextField,
  controllerProps,
  fieldConfig,
  helperText,
  helperTextShrink = false,
  onChange,
  sx,
  ...textFieldProps
}: IFormTextFieldBase<TFieldValues, TName>) => {
  const { control } = useFormContext<TFieldValues>();

  return (
    <Controller<TFieldValues, TName>
      control={control}
      name={fieldConfig.name}
      render={({ field, fieldState }) => {
        return (
          <TextField
            {...field}
            InputLabelProps={{ shrink: true }}
            InputProps={textFieldProps.InputProps}
            error={Boolean(fieldState.error)}
            fullWidth
            helperText={
              fieldState.error?.message || helperText || (helperTextShrink ? undefined : ' ')
            } // Empty space so that layout does not shift when error message appears
            inputRef={field.ref}
            onBlur={() => field.onBlur()}
            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              const value = event?.target?.value;

              if (textFieldProps?.type === 'number' && !/^[0-9.]*$/i.test(value)) {
                return;
              }

              field.onChange(event);
              onChange?.(event);
            }}
            placeholder={fieldConfig.placeholder}
            ref={undefined}
            sx={{
              '& .MuiOutlinedInput-root': { background: '#FFFFFF' },
              mb: -3,
              ...sx,
            }}
            value={field.value || ''}
            {...textFieldProps}
          />
        );
      }}
      {...controllerProps}
    />
  );
};
