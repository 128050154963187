import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { ExternalLink } from 'pages/raas/components';
import { useTimeFilterOptions } from 'pages/raas/DeploymentDetails/hooks/useTimeFilterOptions';
import { useBlockscoutTokens, useRaasDeployment } from 'pages/raas/hooks';
import { useEffect } from 'react';
import { Section } from 'shared/components/Section';
import { TableRowSkeleton } from 'shared/components/Skeleton/TableRowSkeleton';
import { Pagination } from 'shared/components/Table/Pagination';
import { useFuzzySearch } from 'shared/hooks';
import { useTablePagination } from 'shared/hooks/ui';
import { truncateEthAddress } from 'shared/utils/address';
import { getAddress } from 'viem';

import { DateFilterVariant, useTimeFilterStore } from '../../store';

export const TokensSection = () => {
  const { data: rollup } = useRaasDeployment();
  const { selectedTimeFilter } = useTimeFilterStore();
  const { end: to, start: from } = useTimeFilterOptions(
    selectedTimeFilter[DateFilterVariant.ROLLUP_STATS_TOKENS_SECTION] || 'month',
  );
  const { data: blockscoutTokensResult, isPending } = useBlockscoutTokens({ l2: true, from, to });
  const { result: searchResult, search } = useFuzzySearch({
    returnEmpty: true,
    list: blockscoutTokensResult?.items ?? [],
    options: {
      keys: [
        {
          name: 'name',
          weight: 0.5,
        },
        {
          name: 'address',
          weight: 0.3,
        },
        {
          name: 'type',
          weight: 0.2,
        },
      ],
    },
  });
  const {
    currentPage,
    filteredData: data,
    paginationCount,
    setCurrentPage,
  } = useTablePagination({
    data: searchResult.length > 0 ? searchResult : blockscoutTokensResult?.items ?? [],
  });

  useEffect(() => {
    if (searchResult.length > 0 && currentPage > paginationCount) {
      setCurrentPage(1);
    }
  }, [currentPage, paginationCount, searchResult, setCurrentPage]);

  return (
    <Section
      renderContent={
        <>
          <TextField
            fullWidth
            onChange={event => {
              search(event.target.value);
            }}
            placeholder="Filter by (Token name, L2 Address, Type)"
          />

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="captionC">Token Name</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="captionC">L2 Address</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="captionC">Type</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="captionC">Holders</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isPending ? (
                  Array.from({ length: 10 }).map((_, index) => (
                    <TableRowSkeleton cellCount={4} key={index.toString()} />
                  ))
                ) : data?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={4} sx={{ width: '100%', textAlign: 'center' }}>
                      <Typography variant="bodySmall">No data found.</Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  data?.map(({ address, holders, name, type }) => (
                    <TableRow key={[name, address, type].join('-')} sx={{ background: '#fff' }}>
                      <TableCell>
                        <Typography variant="bodySmall">{name || 'Unnamed token'}</Typography>
                      </TableCell>
                      <TableCell>
                        <ExternalLink
                          href={[rollup?.l2Blockscout, 'tokens', getAddress(address)].join('/')}
                          variant="bodySmall"
                        >
                          {truncateEthAddress(getAddress(address))}
                        </ExternalLink>
                      </TableCell>
                      <TableCell>
                        <Typography variant="bodySmall">{type}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="bodySmall">
                          {Number(holders).toLocaleString()}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
              {data && (
                <Pagination
                  paginationOptions={{
                    count: paginationCount,
                    onChange: (_, page) => setCurrentPage(page),
                  }}
                  sx={{ pl: 5, py: 3 }}
                />
              )}
            </Table>
          </TableContainer>
        </>
      }
      title="Tokens"
    />
  );
};
