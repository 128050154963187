import { Box, Button, Grid, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes';
import MultilineTruncatedText from 'shared/components/Formatted/MultilineTruncatedText';
import ImgWithFallback from 'shared/components/ImgWithFallback';
import {
  AAATemplate,
  AAATemplateStatus,
  aAATemplateStatusFromJSON,
} from 'shared/types/protoc-gen/bffaaa';

import { AgentCarouselTags, tagMap } from './CarouselCardTag';
import StoreCardActionsMenu from './StoreCardActionsMenu';

export interface AgentCarouselCardProps extends AAATemplate {
  tagNames: AgentCarouselTags[];
}

export const AgentCarouselCard: React.FC<{ card: AgentCarouselCardProps }> = ({ card }) => {
  const navigate = useNavigate();

  const showDeployButton =
    aAATemplateStatusFromJSON(card?.status) === AAATemplateStatus.AAA_TEMPLATE_STATUS_PUBLISHED;

  return (
    <Box
      data-testid="agent-carousel-card"
      sx={{
        px: 5,
        py: 3,
        background: theme => theme.colors.gradients.sheet,
        border: theme => `1px solid ${theme.colors.functional.subject.border}`,
        color: theme => theme.colors.functional.text.primary,
        height: '100%',
        '&:hover': { background: '#fff' },
      }}
    >
      <Grid
        alignItems="flex-start"
        container
        item
        justifyContent="space-between"
        spacing={1}
        xs={12}
      >
        <Grid container direction="row" gap={2} item xl={8} xs={showDeployButton ? 12 : 8}>
          <ImgWithFallback src={card?.iconLink} />
          <Stack
            sx={{
              width: '100%',
              flexBasis: 0,
              flexGrow: 1,
            }}
          >
            <MultilineTruncatedText text={card?.name} variant="h5" />
            <Box sx={{ '&>*': { width: 'max-content' } }}>
              {card?.tagNames?.map(cur => tagMap?.[cur])}
            </Box>
          </Stack>
        </Grid>
        {showDeployButton ? (
          <Grid
            container
            item
            justifyContent="flex-end"
            order={{ xs: 3, sm: 3, xl: 0 }}
            xl={4}
            xs={12}
          >
            <Button
              fullWidth
              onClick={() => navigate(`${PATHS.AAAS_NEW_DEPLOYMENT}?template=${card?.id}`)}
              sx={{ minWidth: 'unset' }}
            >
              Deploy
            </Button>
          </Grid>
        ) : (
          <Grid container item justifyContent="flex-end" xs={4}>
            <StoreCardActionsMenu template={card} />
          </Grid>
        )}
        <Grid item xs={12}>
          <MultilineTruncatedText text={card?.description} />
        </Grid>
      </Grid>
    </Box>
  );
};
