import { Timeframe } from 'pages/raas/hooks';
import { create } from 'zustand';

import { DateFilterVariant } from './variants';

interface State {
  selectedTimeFilter: Partial<{
    [key in DateFilterVariant]: Timeframe;
  }>;
}

interface Actions {
  setSelectedTimeFilter: (id: string, timeFilter: Timeframe) => void;
}

interface Store extends State, Actions {}

const initState: State = {
  selectedTimeFilter: {
    [DateFilterVariant.OVERVIEW_METRICS_SECTION]: 'month',
    [DateFilterVariant.ROLLUP_STATS_OVERVIEW_SECTION]: 'month',
    [DateFilterVariant.ROLLUP_STATS_EOA_ACCOUNTS_SECTION]: 'month',
    [DateFilterVariant.ROLLUP_STATS_CONTRACTS_SECTION]: 'month',
    [DateFilterVariant.ROLLUP_STATS_TRANSACTIONS_SECTION]: 'month',
    [DateFilterVariant.ROLLUP_STATS_BLOCK_SECTION]: 'month',
    [DateFilterVariant.BRIDGE_METRICS_GENERAL_SECTION]: 'month',
    [DateFilterVariant.BRIDGE_METRICS_DEPOSITS_SECTION]: 'month',
    [DateFilterVariant.BRIDGE_METRICS_WITHDRAWALS_SECTION]: 'month',
    [DateFilterVariant.FEE_TRACKER_GAS_SECTION]: 'month',
    [DateFilterVariant.BLOCKCHAIN_STATS_GENERAL_SECTION]: 'month',
    [DateFilterVariant.PROPOSER_BALANCE_BALANCE_SECTION]: 'month',
    [DateFilterVariant.BATCHER_BALANCE_BALANCE_SECTION]: 'month',
    [DateFilterVariant.L2_NODES_SECTION]: 'month',
    [DateFilterVariant.RPC_USAGE_MONITORING_SECTION]: 'month',
    [DateFilterVariant.BALANCE_ALERT_FEE_VAULTS_SECTION]: 'month',
    [DateFilterVariant.DAILY_RPC_COUNT_SECTION]: 'month',
    [DateFilterVariant.RPS_CHOOSE_METHOD_SECTION]: 'month',
    [DateFilterVariant.RPS_METRIC_SECTION]: 'month',
  },
};

export const useTimeFilterStore = create<Store>(set => ({
  ...initState,
  setSelectedTimeFilter: (id, timeFilter) =>
    set(state => ({
      selectedTimeFilter: {
        ...state.selectedTimeFilter,
        [id]: timeFilter,
      },
    })),
}));
