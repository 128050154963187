import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, Stack } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import { AgreementInfoFormGroup } from 'pages/admin/ManageClients/AgreementInfoFormGroup';
import { BasicInfoFormGroup } from 'pages/admin/ManageClients/BasicInfoFormGroup';
import { AgreementInfoFormValues, BasicInfoFormValues } from 'pages/admin/ManageClients/types';
import { CreateClientFormSchema } from 'pages/admin/ManageClients/validation';
import { ADMIN_PATHS } from 'pages/admin/routes';
import { useCallback } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { bff } from 'shared/api';
import { Section } from 'shared/components';
import { useBreakpoints } from 'shared/hooks/ui/useBreakpoints';
import {
  CreateAgreementReply,
  CreateAgreementRequest,
} from 'shared/types/protoc-gen/bffbillsystem';
import { MUTATION_KEYS, QUERY_KEYS } from 'shared/types/react-query';
import { ObjectSchema } from 'yup';

interface FormValues extends AgreementInfoFormValues, BasicInfoFormValues {}

export const ClientCreateView = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const form = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      agreementLinks: [''],
      agreementStatus: undefined,
      agreementScope: 0,
      paymentFrequency: undefined,
      amount: 0,
      notes: '',
      bindingChains: [],
      clientName: '',
      clientNameInAgreement: '',
      contactEmails: '',
    },
    resolver: yupResolver<FormValues>(
      CreateClientFormSchema as unknown as ObjectSchema<FormValues>,
    ),
  });

  const { addAlert } = useAlerts();
  const { sm } = useBreakpoints();

  const { isPending: isCreateAgreementPending, mutate: createAgreement } = useMutation({
    mutationKey: [MUTATION_KEYS.ADMIN_CLIENT_CREATE_AGREEMENT],
    mutationFn: async (data: FormValues) => {
      const res = await bff.post<CreateAgreementReply>('/bff/bill/agreements', {
        ...data,
        agreementLink: data.agreementLinks?.join(';') || '',
        withdrawFeePercentage: data.withdrawFeePercentage / 100,
      } satisfies CreateAgreementRequest);

      return res.data;
    },
    onSuccess: () => {
      addAlert({
        severity: ALERT_SEVERITY.SUCCESS,
        title: 'Client created successfully',
        desc: 'The client has been created successfully',
      });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ADMIN_CLIENT_LIST] });
      navigate(ADMIN_PATHS.MANAGE_CLIENTS);
    },
    onError: () => {
      addAlert({
        severity: ALERT_SEVERITY.ERROR,
        title: 'Failed to create client',
        desc: 'An error occurred while creating the client',
      });
    },
  });

  const onValid: SubmitHandler<FormValues> = useCallback(
    formValues => {
      console.log('formValues:', formValues);

      createAgreement(formValues);
    },
    [createAgreement],
  );

  return (
    <FormProvider {...form}>
      <Section
        component="form"
        hasBackButton
        onSubmit={form.handleSubmit(onValid)}
        title="Create Client"
      >
        <Box bgcolor="#fff" px={5} py={3}>
          <Grid
            alignItems="start"
            columnSpacing={3}
            container
            justifyContent="center"
            rowSpacing={5}
          >
            <Grid item lg={6} md={12} sm={12} xl={6} xs={12}>
              <BasicInfoFormGroup writeMode />
            </Grid>
            <Grid item lg={6} md={12} sm={12} xl={6} xs={12}>
              <AgreementInfoFormGroup writeMode />

              <Stack direction="row" justifyContent="flex-end" pt={3}>
                <LoadingButton
                  loading={isCreateAgreementPending}
                  sx={{ width: sm ? '100%' : 'fit-content' }}
                  type="submit"
                  variant="contained"
                >
                  Create
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Section>
    </FormProvider>
  );
};
