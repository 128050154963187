import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import {
  Box,
  Collapse,
  Dialog,
  DialogProps,
  Divider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { CreditsRemainingChip } from 'pages/aaas/components/CreditsRemainingChip';
import { PurchaseCreditsButton } from 'pages/aaas/components/PurchaseCreditsButton';
import { useListApps, useListCustomerSubs } from 'pages/aaas/hooks';
import { useVerifyPromoCode } from 'pages/aaas/NewAgentDeployment/hooks/useVerifyPromoCode';
import { ExternalLink } from 'pages/raas/components';
import { showExperimentalFeatures } from 'pages/raas/DeploymentDetails/helpers/visibility';
import { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { PiCaretDownBold } from 'react-icons/pi';
import { PATHS } from 'routes';
import ComingSoonTooltip from 'shared/components/ComingSoonTooltip';
import { DialogContainer, DialogHeader } from 'shared/components/Dialog';
import IconInfo from 'shared/components/icons/IconInfo';
import { PasswordTextField } from 'shared/components/TextField/PasswordTextField';
import { ConditionalTooltip } from 'shared/components/Tooltip/ConditionalTooltip';
import { useBreakpoints } from 'shared/hooks/ui/useBreakpoints';
import {
  CreationAAAMethod,
  creationAAAMethodToJSON,
  CreationAAAParams,
} from 'shared/types/protoc-gen/bffaaa';
import { QUERY_KEYS } from 'shared/types/react-query';

export interface ICreationMethodDialog extends DialogProps {
  onSelectCreationMethod?: (
    creationMethod: CreationAAAMethod,
    creationParams?: CreationAAAParams,
  ) => void;
}

export const CreationMethodDialog: React.FC<ICreationMethodDialog> = ({
  onClose,
  onSelectCreationMethod,
  open,
  ...props
}) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { data: listAppsData } = useListApps();
  const [promoCode, setPromoCode] = useState<string>();
  const [showPromoCodeSection, setShowPromoCodeSection] = useState(false);
  const { data: customerSubs } = useListCustomerSubs();
  const { sm } = useBreakpoints();

  const handleClose = useCallback(() => onClose?.({}, 'backdropClick'), [onClose]);

  const freeTrialUsed = useMemo(() => {
    const filtered = listAppsData?.apps.filter(
      app =>
        String(app.creationMethod) ===
        creationAAAMethodToJSON(CreationAAAMethod.CREATION_AAA_METHOD_FREE_TRIAL),
    );

    if (!filtered) return false;

    return filtered.length > 0;
  }, [listAppsData?.apps]);

  const handleConsumeCredits = useCallback(() => {
    onSelectCreationMethod?.(CreationAAAMethod.CREATION_AAA_METHOD_SUBSCRIPTION);
    handleClose();
  }, [handleClose, onSelectCreationMethod]);

  const handleFreeTrial = useCallback(() => {
    onSelectCreationMethod?.(CreationAAAMethod.CREATION_AAA_METHOD_FREE_TRIAL);
    handleClose();
  }, [handleClose, onSelectCreationMethod]);

  const {
    isError: isVerifyPromoCodeError,
    isPending: isVerifyPromoCodePending,
    mutate: verifyPromoCode,
  } = useVerifyPromoCode({
    onSuccess: (valid, promoCode) => {
      if (valid && promoCode)
        onSelectCreationMethod?.(CreationAAAMethod.CREATION_AAA_METHOD_PROMO_CODE, { promoCode });
      setPromoCode(undefined);
      handleClose();
    },
  });

  useEffect(() => {
    if (open) {
      // refetch whenever dialog is opened
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.VERIFY_FREE_TRIAL_PROMO_CODE] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.VERIFY_SUBSCRIPTION_PROMO_CODE] });
    }
  }, [open, queryClient]);

  return (
    <Dialog
      data-testid="creation-method-dialog"
      fullScreen={sm}
      maxWidth="md"
      open={open}
      {...props}
    >
      <DialogContainer
        component="form"
        onSubmit={e => {
          e?.preventDefault();
          verifyPromoCode(promoCode);
        }}
        sx={{
          minWidth: '750px',
        }}
      >
        <DialogHeader
          onClose={() => onClose?.({}, 'backdropClick')}
          startAdornment={
            <Stack spacing={2}>
              <Typography variant="h5">Choose subscription type</Typography>
              <Typography component="div" variant="caption">
                By proceeding, you agree to our{' '}
                <ExternalLink href={PATHS.TERMS_OF_SERVICE}>terms of service</ExternalLink> and
                acknowledge having read our{' '}
                <ExternalLink href={PATHS.DISCLAIMER}>disclaimer</ExternalLink>.
              </Typography>
              <Stack direction="row" spacing={2}>
                <CreditsRemainingChip />
                <PurchaseCreditsButton label="Buy more" />
              </Stack>
            </Stack>
          }
          sx={{
            alignItems: 'start',
          }}
          title="Verify Promo Code"
        />
        <Box>
          <ComingSoonTooltip show={!showExperimentalFeatures}>
            <LoadingButton
              disabled={
                Number(customerSubs?.totalCredits || 0) - Number(customerSubs?.usedCredits || 0) <=
                0
              }
              fullWidth
              onClick={handleConsumeCredits}
              variant="contained"
            >
              <Stack alignItems="center" justifyContent="center">
                <Typography variant="body1">Launch Agent</Typography>
                <Typography variant="caption">(Uses 1 available Agent Credit)</Typography>
              </Stack>
            </LoadingButton>
          </ComingSoonTooltip>
          <Stack alignItems="center" direction="row" justifyContent="flex-end" mt={1} spacing={1}>
            <Typography
              component="a"
              data-testid="free-trial-button"
              href={!freeTrialUsed ? '#' : undefined}
              onClick={!freeTrialUsed ? handleFreeTrial : undefined}
              sx={{
                display: 'flex',
                alignItems: 'center',
                columnGap: 1,
                cursor: !freeTrialUsed ? 'pointer' : 'not-allowed',
                color: theme =>
                  !freeTrialUsed
                    ? theme.colors.functional.text.link
                    : theme.colors.functional.text.third,
              }}
              variant="captionM"
            >
              {freeTrialUsed ? 'Free trial limit reached' : 'Continue with free trial'}
              <FreeTrialTooltip canFreeTrial={!freeTrialUsed}>
                <IconInfo
                  fill={
                    !freeTrialUsed
                      ? theme.colors.functional.text.link
                      : theme.colors.functional.text.third
                  }
                  sx={{
                    position: 'relative',
                    top: -2,
                    height: '13px',
                    width: '13px',
                  }}
                />
              </FreeTrialTooltip>
            </Typography>
          </Stack>
        </Box>
        <Box>
          <Stack
            direction="row"
            sx={{
              '&>hr': {
                flex: 1,
                position: 'relative',
                top: -10,
                borderColor: theme => theme.colors.functional.text.primary,
              },
            }}
          >
            <Divider />
            <Typography
              data-testid="show-promo-code-button"
              onClick={() => setShowPromoCodeSection(state => !state)}
              px={2}
              sx={{ display: 'flex', alignItems: 'center', columnGap: 1, cursor: 'pointer' }}
            >
              Apply a promo code{' '}
              <Box
                component={PiCaretDownBold}
                sx={{
                  transition: 'all 0.2s ease-in-out',
                  transform: showPromoCodeSection ? 'rotate(-180deg)' : 'none',
                }}
              />
            </Typography>
            <Divider />
          </Stack>

          <Collapse in={showPromoCodeSection}>
            <Stack
              direction="row"
              mt={4}
              spacing={2}
              sx={{ alignItems: 'center', position: 'relative' }}
            >
              <PasswordTextField
                data-testid="promo-code-input"
                error={isVerifyPromoCodeError}
                helperText={isVerifyPromoCodeError ? 'Invalid promo code.' : undefined}
                onChange={e => setPromoCode(e.target.value)}
                placeholder="PROMO123"
                sx={{ flex: 1 }}
              />
              <SecondaryButton
                data-testid="apply-promo-code-button"
                disabled={!promoCode}
                loading={isVerifyPromoCodePending}
                sx={{ '&&': { height: '70px', minWidth: '120px', alignSelf: 'flex-start' } }}
                type="submit"
              >
                <Typography variant="body1">Apply</Typography>
              </SecondaryButton>
            </Stack>
          </Collapse>
        </Box>
      </DialogContainer>
    </Dialog>
  );
};

const FreeTrialTooltip: FC<{ canFreeTrial?: boolean; children: ReactElement }> = ({
  canFreeTrial,
  children,
}) => {
  return (
    <ConditionalTooltip
      enabled
      title={
        canFreeTrial
          ? 'For a free trial, instances will expire in 48 hours.'
          : "You've currently used your free trial, it will be available again after 48 hours since the last free trial deployment."
      }
      wrapped
    >
      {children}
    </ConditionalTooltip>
  );
};

const SecondaryButton: FC<LoadingButtonProps> = ({ children, sx, ...props }) => (
  <LoadingButton
    sx={{
      border: theme => `1px solid ${theme.colors.functional.subject.border}`,
      '&, &:hover': theme => ({
        background: theme.colors.gradients.metal,
        color: theme.colors.functional.text.primary,
      }),
      ...sx,
    }}
    {...props}
  >
    {children}
  </LoadingButton>
);
