import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { bff } from 'shared/api';
import { WithdrawBill, WithdrawMonthlyBillReply } from 'shared/types/protoc-gen/bffbillsystem';
import { QUERY_KEYS } from 'shared/types/react-query';

interface IUseWFBills
  extends Omit<UseQueryOptions<unknown, Error, WithdrawBill[]>, 'queryKey' | 'queryFn'> {
  params: {
    rollupId: string;
  };
}

export const useWFBills = (opts: IUseWFBills) =>
  useQuery({
    queryKey: [QUERY_KEYS.RAAS_WITHDRAW_FEE_BILLS, opts?.params],
    queryFn: async (): Promise<WithdrawBill[]> => {
      const response = await bff.get<WithdrawMonthlyBillReply>('/bff/bill/withdraw_bills', {
        params: {
          opstackId: opts?.params?.rollupId,
        },
      });

      return response.data.withdrawBills;
    },
    ...opts,
  });
