import { DateRangePicker } from 'shared/components/Date';
import { MetricGridItem } from 'shared/components/metric/MetricGridItem';
import { Section } from 'shared/components/Section';

import { DateFilterVariant } from '../../store';
import { AccountsGrowthMetric } from './AccountsGrowthMetric';
import { ActiveAccountsMetric } from './ActiveAccountsMetric';
import { NewAccountMetric } from './NewAccountMetric';

export const EoaAccountsSection = () => {
  return (
    <Section
      renderGridContent={
        <>
          <MetricGridItem item>
            <AccountsGrowthMetric />
          </MetricGridItem>
          <MetricGridItem item>
            <ActiveAccountsMetric />
          </MetricGridItem>
          <MetricGridItem item>
            <NewAccountMetric />
          </MetricGridItem>
        </>
      }
      renderSubHeaderEndContent={
        <DateRangePicker variant={DateFilterVariant.ROLLUP_STATS_EOA_ACCOUNTS_SECTION} />
      }
      sectionContentSx={{ p: '0px' }}
      title="EOA Accounts"
    />
  );
};
