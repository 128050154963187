import {
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useRaasMetrics } from 'pages/raas/hooks';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Section } from 'shared/components';
import { Panel } from 'shared/types/protoc-gen/raas-metrics';

import { useTimeFilterOptions } from '../../hooks/useTimeFilterOptions';
import { IRpcMonitoringSections } from './RpcUsageMonitoringView';

export const RpmByMethodsSection: React.FC<IRpcMonitoringSections> = ({ instance }) => {
  const { rollupId } = useParams();

  const { end, timeframe } = useTimeFilterOptions('hour');

  const { data, isLoading } = useRaasMetrics({
    panel: Panel.PANEL_OPSTACK_RPM_BY_METHOD,
    timeframe,
    rollupId,
    start: end,
    end,
    step: '1h',
    rpcInstance: instance,
    template: 'YYYY-MM-DD HH:mm',
  });

  const methodsData = useMemo(() => {
    const singleData = data?.data?.[0] ?? {};

    return Object.entries(singleData).filter(([key]) => key !== 'date');
  }, [data]);

  const formattedDate = data?.data?.[0]?.date
    ? dayjs(data.data[0].date).format('MMM DD YYYY, hh:mm A')
    : '';

  return (
    <Section
      renderSubHeaderStartContent={
        <Stack alignItems="end" direction="row" spacing={1}>
          <Typography variant="bodySmallC">RPM by Method</Typography>
          {isLoading ? (
            <Skeleton variant="text" width="100px" />
          ) : (
            <Typography variant="caption">{`as of ${formattedDate}`}</Typography>
          )}
        </Stack>
      }
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {['Method Name', 'Requests/Min'].map(header => (
                <TableCell key={header}>
                  <Typography variant="captionC">{header}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {methodsData
              .sort((a, b) => Number(b[1]) - Number(a[1]))
              .map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{Number(value).toLocaleString()}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Section>
  );
};
