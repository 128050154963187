import { create } from 'zustand';

import { DateFilterVariant } from './variants';

interface DateRange {
  from: Date;
  to: Date;
}

interface State {
  selectedDateRange: Partial<{
    [id: string]: DateRange | null;
  }>;
}

interface Actions {
  setSelectedDateRange: (id: string, range: DateRange | null) => void;
}

interface Store extends State, Actions {}

const LOCAL_STORAGE_PREFIX = 'dateRange_';

const loadFromLocalStorage = (id: DateFilterVariant): DateRange | null => {
  try {
    const storedData = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}${id}`);

    if (storedData) {
      const parsedData = JSON.parse(storedData);

      return { from: new Date(parsedData.from), to: new Date(parsedData.to) };
    }
  } catch (error) {
    console.error(`Failed to load date range for ${id} from localStorage`, error);
  }

  return null;
};

const getLastMonthRange = (): DateRange => {
  const today = new Date();
  const from = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());

  return { from, to: today };
};

const initState: State = {
  selectedDateRange: Object.values(DateFilterVariant).reduce((acc, variant) => {
    acc[variant] = loadFromLocalStorage(variant) ?? getLastMonthRange();

    return acc;
  }, {} as Record<DateFilterVariant, DateRange | null>),
};

export const useDateRangeStore = create<Store>(set => ({
  ...initState,

  setSelectedDateRange: (id, range) => {
    set(state => {
      const updatedRange = { ...state.selectedDateRange, [id]: range };

      try {
        localStorage.setItem(`${LOCAL_STORAGE_PREFIX}${id}`, JSON.stringify(range));
      } catch (error) {
        console.error(`Failed to save date range for ${id} to localStorage`, error);
      }

      return { selectedDateRange: updatedRange };
    });
  },
}));
