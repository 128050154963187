import { RaasMetricsData, RaasMetricsResult, useRaasMetrics } from 'pages/raas/hooks';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Metric } from 'shared/components/metric';
import { Panel } from 'shared/types/protoc-gen/raas-metrics';

import { useDateRangeFilterOptions } from '../../hooks/useDateRangeFilterOptions';
import { DateFilterVariant } from '../../store';
import { getRpsTemplate } from './helpers';

interface IRpmByMethodsMetric {
  instance: string;
  method: string;
}

export const RpsByMethodsMetric: React.FC<IRpmByMethodsMetric> = ({ instance, method }) => {
  const { rollupId } = useParams();

  const { end, start, step, timeframe } = useDateRangeFilterOptions(
    DateFilterVariant.RPS_CHOOSE_METHOD_SECTION,
  );

  const { data, isPending, isRefetching, refetch } = useRaasMetrics({
    panel: Panel.PANEL_OPSTACK_RPS_BY_METHOD,
    timeframe,
    rollupId,
    start,
    end,
    step,
    rpcInstance: instance,
    template: getRpsTemplate(timeframe),
  });

  const graphData = useMemo(() => {
    if (method === 'All methods') {
      return data;
    }

    return transformDataToSingleMethod(data, method);
  }, [data, method]);

  return (
    <Metric
      chartType="area"
      data={graphData}
      description={'Requests per minute for each method'}
      hideLegend
      id="requests-by-methods"
      isRefetching={isRefetching}
      loading={isPending}
      moreMenuProps={{
        onClickRefetch: () => refetch(),
      }}
      responsiveContainerSx={{ minHeight: '250px' }}
      timeframe={timeframe}
      title="RPS by method"
      xAxisProps={{
        dataKey: 'date',
      }}
    />
  );
};

const transformDataToSingleMethod = (
  data: RaasMetricsResult<RaasMetricsData> | undefined,
  method: string,
) => {
  if (!data) return;

  const transformedData = data.data.map(item => {
    const transformedItem: { date: string; [key: string]: any } = { date: item.date };

    if (method in item) {
      transformedItem[method] = item[method];
    }

    return transformedItem;
  });

  const dataKey = data.dataKeys.find(dataKey => dataKey?.name === method);

  const payload = {
    data: transformedData,
    dataKeys: dataKey ? [dataKey] : [],
  };

  return payload;
};
