import { InfoOutlined } from '@mui/icons-material';
import { darken, Fab, Stack, StackProps, Typography } from '@mui/material';
import { FaqDialog } from 'pages/aaas/components/FaqDialog';
import { AAAS_PATHS } from 'pages/aaas/routes';
import { showExperimentalFeatures } from 'pages/raas/DeploymentDetails/helpers/visibility';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Banner } from 'shared/components/Banner';
import { IconTelegram } from 'shared/components/icons/IconTelegram';
import { IPageBanner, PageBanner, PageBannerVariant } from 'shared/components/Layout/PageBanner';
import { PageBannerTabItem } from 'shared/components/Layout/TabNavigation';
import { useDisclosure } from 'shared/hooks/ui';
import { BannerVariant, useBannerStore } from 'shared/stores/useBannerStore';

export interface IAaasLayout extends StackProps {
  bannerDefaultOpen?: boolean;
  pageBannerProps?: IPageBanner;
}

export const AaasLayout: React.FC<IAaasLayout> = props => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    !location.pathname.includes(AAAS_PATHS.AAAS_BOUNTIES) ? 0 : 1,
  );
  const { banners, setBanner } = useBannerStore();
  const { onClose: onCloseWarningBanner } = useDisclosure({
    defaultOpen: props.bannerDefaultOpen ?? banners[BannerVariant.AAA_WARNING],
    onClose: () => setBanner(BannerVariant.AAA_WARNING, false),
  });
  const {
    onClose: onCloseFaqDialog,
    onOpen: onOpenFaqDialog,
    open: isOpenFaqDialog,
  } = useDisclosure();

  const tabs = useMemo<Array<PageBannerTabItem>>(
    () => [
      {
        label: 'Deployments',
        onClick: () => {
          navigate(AAAS_PATHS.AAAS_DEPLOYMENTS, { replace: true });
          setActiveTabIndex(0);
        },
      },
      {
        label: 'Bounties',
        disabled: !showExperimentalFeatures,
        onClick: () => {
          navigate(AAAS_PATHS.AAAS_BOUNTIES, { replace: true });
          setActiveTabIndex(1);
        },
        endAdornment: (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              px: 1,
              py: 0.5,
              background: theme =>
                showExperimentalFeatures
                  ? theme.colors.gradients.leaf
                  : darken(theme.colors.schema.metalPrimary, 0.1),
            }}
          >
            <Typography
              sx={{
                color: theme => theme.colors.functional.text.primary,
              }}
              variant="captionC"
            >
              {showExperimentalFeatures ? 'New' : 'Coming soon'}
            </Typography>
          </Stack>
        ),
      },
    ],
    [navigate],
  );

  return (
    <>
      <FaqDialog onClose={onCloseFaqDialog} open={isOpenFaqDialog} />

      <Stack {...props} sx={{ mb: '164px', ...props.sx }}>
        <PageBanner
          variant={PageBannerVariant.AAAS}
          {...props?.pageBannerProps}
          tabNavigationProps={{
            activeIndex: activeTabIndex,
            items: showExperimentalFeatures ? tabs : [],
          }}
        />
        <Banner
          data-testid="autonome-warning-banner"
          in={banners[BannerVariant.AAA_WARNING]}
          onClose={onCloseWarningBanner}
        >
          <Typography data-testid="autonome-warning-banner-text">
            Your agent may be terminated after expiration. Please remember to back up all your API
            and private keys safely to avoid any loss of keys or funds.
          </Typography>
        </Banner>

        {props.children}

        <Fab
          id="faq-floating-action-button"
          onClick={onOpenFaqDialog}
          sx={{
            '&,&:hover': {
              background: theme => theme.colors.gradients.altLayer,
            },
            position: 'fixed',
            bottom: 164,
            right: 16,
          }}
        >
          <Stack alignItems="center" direction="row" spacing={0.5}>
            <InfoOutlined
              sx={{
                color: '#fff',
                fontSize: 32,
              }}
            />
          </Stack>
        </Fab>
        <Fab
          id="telegram-floating-action-button"
          onClick={() => window.open('https://t.me/+AOWj9uu9MzU2NjY1', '_blank')}
          sx={{
            background: '#2AABEE',
            '&:hover': {
              background: darken('#2AABEE', 0.1),
            },
            position: 'fixed',
            bottom: 88,
            right: 16,
          }}
        >
          <Stack alignItems="center" direction="row" spacing={0.5}>
            <IconTelegram sx={{ color: '#fff', height: 32, width: 32 }} />
          </Stack>
        </Fab>
        <Fab
          id="mava-floating-action-button"
          sx={{
            background: theme => theme.colors.functional.container.default,
            position: 'fixed',
            bottom: 12,
            right: 17,
          }}
        />
      </Stack>
    </>
  );
};
