import { Theme } from '@mui/material';

export const getMuiDayPickerStyles = (theme: Theme) => `
  .rdp-root {
    --rdp-cell-size: 40px;
    --rdp-accent-color: ${theme.colors.functional.container.primary};
    --rdp-background-color: ${theme.colors.functional.container.primary};
    --rdp-accent-color-dark: ${theme.palette.primary.dark};
    --rdp-background-color-dark: ${theme.palette.primary.dark};
    --rdp-outline: 2px solid ${theme.colors.functional.container.primary};
    --rdp-outline-selected: 2px solid ${theme.colors.functional.container.primary};
    margin: 0;
  }
  
  .rdp-months {
    justify-content: center;
  }
  
  .rdp-day_selected, 
  .rdp-day_selected:focus-visible, 
  .rdp-day_selected:hover {
    background-color: ${theme.colors.functional.container.primary};
    color: ${theme.palette.primary.contrastText};
    border-radius: 0;
  }

  .rdp-day_today {
    border: 1px solid ${theme.colors.functional.container.primary};
    background-color: transparent;
  }
  
  .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    background-color: ${theme.colors.functional.container.primary}10;
  }
  
  .rdp-head_cell {
    font-weight: 500;
    font-size: 0.875rem;
    color: ${theme.palette.text.secondary};
  }
  
  .rdp-day_range_start,
  .rdp-day_range_end {
    background-color: ${theme.colors.functional.container.primary};
  }
  
  .rdp-day_range_middle {
    background-color: ${theme.colors.functional.container.primary}20;
    color: ${theme.palette.text.primary};
  }
  
  .rdp-day_range_middle:hover {
    background-color: ${theme.colors.functional.container.primary}30;
  }
`;
