import { useRaasMetrics } from 'pages/raas/hooks';
import { useParams } from 'react-router-dom';
import { Metric } from 'shared/components/metric';
import { Panel } from 'shared/types/protoc-gen/raas-metrics';

import { useDateRangeFilterOptions } from '../../hooks/useDateRangeFilterOptions';
import { DateFilterVariant } from '../../store';

export const CPUMetric = () => {
  const { rollupId } = useParams();

  const { end, start, step, timeframe } = useDateRangeFilterOptions(
    DateFilterVariant.BLOCKCHAIN_STATS_GENERAL_SECTION,
  );

  const { data: result, isPending } = useRaasMetrics({
    panel: Panel.PANEL_OPSTACK_CPU,
    timeframe,
    rollupId,
    start,
    end,
    step,
  });

  return (
    <Metric
      chartType="line"
      data={result}
      description={'CPU usage of each node over time'}
      hideLegend={false}
      id={'cpu'}
      legendDirection={'column'}
      legendSpacing={'4px'}
      loading={isPending}
      title={'CPU'}
    />
  );
};
