import { useBlockscoutMetrics } from 'pages/raas/hooks';
import { Metric } from 'shared/components/metric';
import { gweiFormatter } from 'shared/utils/recharts';

import { useDateRangeFilterOptions } from '../../hooks/useDateRangeFilterOptions';
import { DateFilterVariant } from '../../store';

export const GasUsedGrowthMetric = () => {
  const {
    end: to,
    start: from,
    timeframe,
  } = useDateRangeFilterOptions(DateFilterVariant.FEE_TRACKER_GAS_SECTION);

  const { data: result, isPending } = useBlockscoutMetrics({
    variant: 'gasUsedGrowth',
    l2: true,
    timeframe,
    from,
    to,
  });

  return (
    <Metric
      data={result}
      description={'Cumulative gas used for the period'}
      id={'gas-used-growth'}
      loading={isPending}
      title={'Gas used growth'}
      tooltipValueFormatter={gweiFormatter}
      yAxisProps={{
        tickFormatter: gweiFormatter,
      }}
    />
  );
};
