import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useRaasDeployment } from 'pages/raas/hooks';
import { raas } from 'shared/api';
import { GetRpcInstancesReply, GetRpcInstancesRequest } from 'shared/types/protoc-gen/raas-metrics';
import { QUERY_KEYS } from 'shared/types/react-query';

export const useRpcInstances = () => {
  const { data: rollup } = useRaasDeployment();

  return useQuery<string[]>({
    queryKey: [QUERY_KEYS.GET_RAAS_RPC_INSTANCES],
    queryFn: async (): Promise<string[]> => {
      const res = await raas.get<
        GetRpcInstancesReply,
        AxiosResponse<GetRpcInstancesReply>,
        GetRpcInstancesRequest
      >('/bff/metrics/rpc_instances', {
        params: {
          opstackId: rollup?.id,
        },
      });

      return res.data.proxydInstances;
    },
    enabled: !!rollup?.id,
  });
};
