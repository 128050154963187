export enum DateFilterVariant {
  OVERVIEW_METRICS_SECTION = 'OVERVIEW_METRICS_SECTION',
  ROLLUP_STATS_OVERVIEW_SECTION = 'ROLLUP_STATS_OVERVIEW_SECTION',
  ROLLUP_STATS_EOA_ACCOUNTS_SECTION = 'ROLLUP_STATS_EOA_ACCOUNTS_SECTION',
  ROLLUP_STATS_TRANSACTIONS_SECTION = 'ROLLUP_STATS_TRANSACTIONS_SECTION',
  ROLLUP_STATS_BLOCK_SECTION = 'ROLLUP_STATS_BLOCK_SECTION',
  ROLLUP_STATS_TOKENS_SECTION = 'ROLLUP_STATS_TOKENS_SECTION',
  ROLLUP_STATS_CONTRACTS_SECTION = 'ROLLUP_STATS_CONTRACTS_SECTION',
  BRIDGE_METRICS_GENERAL_SECTION = 'BRIDGE_METRICS_GENERAL_SECTION',
  BRIDGE_METRICS_DEPOSITS_SECTION = 'BRIDGE_METRICS_DEPOSITS_SECTION',
  BRIDGE_METRICS_WITHDRAWALS_SECTION = 'BRIDGE_METRICS_WITHDRAWALS_SECTION',
  FEE_TRACKER_GAS_SECTION = 'FEE_TRACKER_GAS_SECTION',
  BLOCKCHAIN_STATS_GENERAL_SECTION = 'BLOCKCHAIN_STATS_GENERAL_SECTION',
  PROPOSER_BALANCE_BALANCE_SECTION = 'PROPOSER_BALANCE_BALANCE_SECTION',
  BATCHER_BALANCE_BALANCE_SECTION = 'BATCHER_BALANCE_BALANCE_SECTION',
  L2_NODES_SECTION = 'L2_NODES_SECTION',
  RPC_USAGE_MONITORING_SECTION = 'RPC_USAGE_MONITORING_SECTION',
  BALANCE_ALERT_FEE_VAULTS_SECTION = 'BALANCE_ALERT_FEE_VAULTS_SECTION',
  DAILY_RPC_COUNT_SECTION = 'DAILY_RPC_COUNT_SECTION',
  RPS_CHOOSE_METHOD_SECTION = 'RPS_CHOOSE_METHOD_SECTION',
  RPS_METRIC_SECTION = 'RPS_METRIC_SECTION',
}
