import { BASE_FEE_ADDR } from 'pages/raas/constants/addresses';
import { useBlockscoutBalanceMetrics, useRaasDeployment } from 'pages/raas/hooks';
import { Metric } from 'shared/components/metric';
import { addressFormatter } from 'shared/utils/recharts';
import { formatTokenWithSymbol } from 'shared/utils/strings';

import { useDateRangeFilterOptions } from '../../hooks/useDateRangeFilterOptions';
import { DateFilterVariant } from '../../store';

export const BaseFeeBalanceMetric = () => {
  const { data: rollup } = useRaasDeployment();

  const {
    end: to,
    start: from,
    timeframe,
  } = useDateRangeFilterOptions(DateFilterVariant.FEE_TRACKER_GAS_SECTION);

  const { data: result, isPending } = useBlockscoutBalanceMetrics({
    account: BASE_FEE_ADDR,
    l2: true,
    timeframe,
    to,
    from,
  });

  return (
    <Metric
      data={result}
      description={'Base fee balance per period'}
      id={'base-fee-balance'}
      loading={isPending}
      title={'Base fee balance'}
      tooltipKeyFormatter={addressFormatter}
      tooltipValueFormatter={value => formatTokenWithSymbol(value, rollup?.tokenMetadata?.symbol)}
      yAxisProps={{
        tickFormatter: value => formatTokenWithSymbol(value, rollup?.tokenMetadata?.symbol),
      }}
    />
  );
};
