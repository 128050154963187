import { useBlockscoutMetrics } from 'pages/raas/hooks';
import { Metric } from 'shared/components/metric';

import { useDateRangeFilterOptions } from '../../hooks/useDateRangeFilterOptions';
import { DateFilterVariant } from '../../store';

export const NewTransactionsMetric = () => {
  const {
    end: to,
    start: from,
    timeframe,
  } = useDateRangeFilterOptions(DateFilterVariant.ROLLUP_STATS_TRANSACTIONS_SECTION);
  const { data: result, isPending } = useBlockscoutMetrics({
    variant: 'newTxns',
    timeframe,
    from,
    to,
  });

  return (
    <Metric
      data={result}
      description={'Number of new transactions'}
      id={'new-transactions'}
      loading={isPending}
      title={'New transactions'}
    />
  );
};
