import { LoadingButton } from '@mui/lab';
import { Box, Menu, MenuItem, Skeleton, Stack, SxProps, Theme, Typography } from '@mui/material';
import { BASE_FEE_ADDR, L1_FEE_ADDR, SEQUENCER_FEE_ADDR } from 'pages/raas/constants/addresses';
import { getTokenSymbol } from 'pages/raas/DeploymentDetails/utils';
import { SettlementLayer } from 'pages/raas/helpers/labels';
import { useRaasDeployment } from 'pages/raas/hooks';
import { useMemo, useState } from 'react';
import { Section } from 'shared/components';
import { AddressLink, CopyableAddress } from 'shared/components/Formatted';
import { AnimatedIconChevronDown } from 'shared/components/icons/AnimatedIconChevronDown';
import { ConditionalTooltip } from 'shared/components/Tooltip/ConditionalTooltip';
import { TextTooltip } from 'shared/components/Tooltip/TextTooltip';
import { useMenuDisclosure } from 'shared/hooks/ui/useMenuDisclosure';
import { FeeVault, feeVaultFromJSON, Rollup } from 'shared/types/protoc-gen/raas-rollup';
import { countLeadingZeroes } from 'shared/utils/number';
import { commify, formatWithPrecision } from 'shared/utils/strings';
import { Address } from 'viem';

import { settlementLayerExplorerUrl } from '../../ProposerView/constants';
import { useWFStatus, useWFTrigger } from '../hooks';
import { WithdrawalSubSectionHeader } from './WithdrawalSubSectionHeader';

interface IOverviewItem {
  balance: number;
  variant: FeeCollections;
  l1FeeVault?: number;
  baseFeeVault?: number;
  sequencerFeeVault?: number;
  keys?: Array<string>;
  sx?: SxProps<Theme>;
  isLoading: boolean;
  rollup?: Rollup;
}

interface IFeeVaultRow {
  title: string;
  value: string;
  isLoading: boolean;
  description: string;
}

enum FeeCollections {
  Vault = 'Vault',
  Recipient = 'Recipient',
}

type AccountMap = {
  label: string;
  account: Address;
  vaultBalance: number;
  receiptBalance: number;
  receiptSigners: Array<string>;
  vault: FeeVault;
};

const accounts: Omit<AccountMap, 'vaultBalance' | 'receiptBalance' | 'receiptSigners'>[] = [
  { label: 'Base fee vault balance', account: BASE_FEE_ADDR, vault: FeeVault.BASE_FEE_VAULT },
  { label: 'L1 fee vault balance', account: L1_FEE_ADDR, vault: FeeVault.L1_FEE_VAULT },
  {
    label: 'Sequencer fee vault balance',
    account: SEQUENCER_FEE_ADDR,
    vault: FeeVault.SEQUENCER_FEE_VAULT,
  },
];

const feeVaultLabel = {
  [FeeVault.BASE_FEE_VAULT]: 'Base Fee Vault',
  [FeeVault.L1_FEE_VAULT]: 'L1 Fee Vault',
  [FeeVault.SEQUENCER_FEE_VAULT]: 'Sequencer Fee Vault',
  [FeeVault.UNRECOGNIZED]: 'Unrecognized',
};

export const FeeCollectionSection: React.FC = () => {
  const { data: rollup } = useRaasDeployment();
  const { isPending: withdrawTriggerPending, mutate: withdrawTrigger } = useWFTrigger();
  const { data: withdrawStatuses, isPending: getBalancesPending } = useWFStatus({
    params: { rollupId: rollup?.id ?? '' },
    enabled: Boolean(rollup?.id),
  });

  const {
    anchorEl: feeVaultMenuAnchorEl,
    onClose: onCloseFeeVaultMenu,
    onOpen: onOpenFeeVaultMenu,
    open: openedFeeVaultMenu,
  } = useMenuDisclosure();

  const [feeVault, setFeeVault] = useState<FeeVault>(FeeVault.L1_FEE_VAULT);

  const currentVault = withdrawStatuses?.find(v => v.vault === feeVault);

  const minWithdrawalBalance = currentVault?.minWithdrawalAmount ?? '0';

  const canWithdraw =
    Number(currentVault?.vaultBalance || 0) < Number(currentVault?.minWithdrawalAmount || 0);

  const balances = useMemo(() => {
    const uniqueBalances = new Map<Address, AccountMap>();

    withdrawStatuses?.forEach(({ receiptBalance, receiptSigners, vault, vaultBalance }) => {
      const account = accounts.find(v => v.vault === vault);

      if (account) {
        const vaultAmount = +vaultBalance;
        const receiptAmount = +receiptBalance;

        uniqueBalances.set(account.account, {
          vault,
          label: account.label,
          account: account.account,
          vaultBalance: vaultAmount,
          receiptBalance: receiptAmount,
          receiptSigners,
        });
      }
    });

    return Array.from(uniqueBalances.values());
  }, [withdrawStatuses]);

  const totalVaultBalance = useMemo(
    () => balances?.reduce((acc, { vaultBalance }) => acc + vaultBalance, 0),
    [balances],
  );

  const selectedReceiptBalance = useMemo(() => {
    const selectedVault = balances?.find(vault => vault.vault === feeVault);

    return selectedVault?.receiptBalance ?? 0;
  }, [balances, feeVault]);

  return (
    <Section title="Fee Collection">
      <WithdrawalSubSectionHeader title="Overview" />

      <Stack direction="row" width="100%">
        <OverviewItem
          balance={totalVaultBalance}
          baseFeeVault={balances[FeeVault.BASE_FEE_VAULT]?.vaultBalance}
          isLoading={getBalancesPending}
          keys={balances[feeVault]?.receiptSigners}
          l1FeeVault={balances[FeeVault.L1_FEE_VAULT]?.vaultBalance}
          rollup={rollup}
          sequencerFeeVault={balances[FeeVault.SEQUENCER_FEE_VAULT]?.vaultBalance}
          sx={{ p: '24px 40px' }}
          variant={FeeCollections.Vault}
        />
        <OverviewItem
          balance={selectedReceiptBalance}
          isLoading={getBalancesPending}
          keys={balances[feeVault]?.receiptSigners}
          rollup={rollup}
          sx={{
            p: '24px 24px',
            borderLeft: theme => `1px solid ${theme.colors.functional.subject.border}`,
          }}
          variant={FeeCollections.Recipient}
        />
      </Stack>

      <WithdrawalSubSectionHeader title="Manual Collection" />

      <Stack spacing="24px" sx={{ p: '24px 40px' }} width="100%">
        <Stack spacing="16px" width="100%">
          <Stack alignItems="center" direction="row">
            <Box sx={{ width: '100%' }}>
              <Typography variant="bodySmall">From</Typography>
            </Box>

            <Stack alignItems="center" direction="row" spacing={1} width="100%">
              <AddressLink
                address={String(accounts.find(account => account.vault === feeVault)?.account)}
                href={`${rollup?.explorer}/address/${
                  accounts.find(account => account.vault === feeVault)?.account
                }`}
                leadingChars={7}
                separator="......"
              />

              <LoadingButton
                disabled={getBalancesPending}
                loading={getBalancesPending}
                onClick={onOpenFeeVaultMenu}
                sx={theme => ({
                  border: `1px solid ${theme.colors.functional.subject.border}`,
                  height: '45px',
                  '&, &:hover': {
                    background: '#fff',
                    color: theme.colors.functional.text.primary,
                    p: 0,
                  },
                })}
              >
                <Stack direction="row" spacing="32px" sx={{ p: '16px' }}>
                  <Typography variant="bodySmallM">{feeVaultLabel[feeVault]}</Typography>
                  <AnimatedIconChevronDown open={openedFeeVaultMenu} />
                </Stack>
              </LoadingButton>

              <Menu
                anchorEl={feeVaultMenuAnchorEl}
                onClose={onCloseFeeVaultMenu}
                open={openedFeeVaultMenu}
              >
                {Object.values(FeeVault)
                  .filter(v => +v >= 0)
                  .map(v => {
                    const feeVault = feeVaultFromJSON(v);
                    const vault = feeVaultLabel[feeVault];

                    return (
                      <MenuItem
                        key={vault}
                        onClick={() => {
                          setFeeVault(feeVault);
                          onCloseFeeVaultMenu();
                        }}
                      >
                        {vault}
                      </MenuItem>
                    );
                  })}
              </Menu>
            </Stack>
          </Stack>

          <Stack alignItems="center" direction="row">
            <Box sx={{ width: '100%' }}>
              <Typography variant="bodySmall">To</Typography>
            </Box>

            <Stack direction="row" spacing={1} width="100%">
              <AddressLink
                address={currentVault?.receipt ?? ''}
                href={
                  balances[feeVault]?.receiptSigners?.length > 0
                    ? `https://app.safe.global/home?safe=eth:${currentVault?.receipt}`
                    : `${
                        settlementLayerExplorerUrl[rollup?.settlementLayer as SettlementLayer]
                      }address/${currentVault?.receipt}`
                }
                leadingChars={7}
                separator="......"
              />

              <Typography variant="captionC">FEE RECIPIENT</Typography>
            </Stack>
          </Stack>

          <Stack alignItems="center" direction="row">
            <Box sx={{ width: '100%' }}>
              <Typography variant="bodySmall">Collection amount</Typography>
            </Box>

            <Typography variant="bodySmallM" width="100%">
              {`${commify(
                formatWithPrecision(
                  balances[feeVault]?.vaultBalance ?? 0,
                  countLeadingZeroes(balances[feeVault]?.vaultBalance ?? 0) >= 6 ? 2 : 4,
                ),
              )} ${getTokenSymbol(rollup)}`}
            </Typography>
          </Stack>

          <Stack alignItems="center" direction="row">
            <Box sx={{ width: '100%' }}>
              <Typography variant="bodySmall">Minimum withdrawal balance</Typography>
            </Box>

            <Typography variant="bodySmallM" width="100%">
              {getBalancesPending ? (
                <Skeleton variant="text" width="20%" />
              ) : (
                `${formatWithPrecision(+minWithdrawalBalance)} ${getTokenSymbol(rollup)}`
              )}
            </Typography>
          </Stack>

          <Stack alignItems="center" direction="row">
            <Box sx={{ width: '100%' }}>
              <Typography variant="bodySmall">Target Network</Typography>
            </Box>

            <Typography variant="bodySmallM" width="100%">
              {getBalancesPending ? (
                <Skeleton variant="text" width="20%" />
              ) : (
                rollup?.settlementLayer
              )}
            </Typography>
          </Stack>
        </Stack>

        <ConditionalTooltip
          enabled
          title={`The vault balance has not reached the minimum withdrawal balance (${
            currentVault?.minWithdrawalAmount
          } ${getTokenSymbol(rollup)}) required.`.trim()}
        >
          <LoadingButton
            disabled={getBalancesPending || withdrawTriggerPending || canWithdraw}
            loading={withdrawTriggerPending}
            onClick={() => withdrawTrigger({ opstackId: rollup?.id ?? '', vault: feeVault })}
            sx={theme => ({
              width: '152px',
              '&, &:hover': {
                background: theme.colors.schema.leafPrimary,
              },
            })}
          >
            <Typography
              fontSize="14px"
              sx={{ color: theme => theme.colors.functional.text.primary }}
              variant="bodySmall"
            >
              Withdraw Now
            </Typography>
          </LoadingButton>
        </ConditionalTooltip>
      </Stack>
    </Section>
  );
};

const OverviewItem: React.FC<IOverviewItem> = ({
  balance = 0,
  baseFeeVault = 0,
  isLoading = true,
  keys = [],
  l1FeeVault = 0,
  rollup,
  sequencerFeeVault = 0,
  sx,
  variant,
}) => {
  const formatEthNumber = (value: number) =>
    `${commify(
      formatWithPrecision(value, countLeadingZeroes(value) >= 6 ? 2 : 4),
    )} ${getTokenSymbol(rollup)}`;

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <Stack
        alignItems={keys.length > 0 ? 'start' : 'end'}
        direction={keys.length > 0 ? 'column' : 'row'}
        justifyContent="space-between"
        spacing="24px"
      >
        <Stack spacing="24px">
          <TextTooltip
            tooltipText={
              variant === FeeCollections.Recipient
                ? 'Balance in ETH for the multisig fee recipient in L1'
                : 'The total balance of the 3 vaults in ETH'
            }
          >
            {variant === FeeCollections.Recipient ? 'Fee Recipient' : 'Fee Vault'}
          </TextTooltip>

          <Stack>
            <Stack spacing="8px">
              <Typography variant="caption">Total balance</Typography>
              {isLoading ? (
                <Skeleton variant="text" width="35%" />
              ) : (
                <Typography sx={{ whiteSpace: 'nowrap' }} variant="h5">
                  {formatEthNumber(balance)}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Stack>

        <Box>
          {variant === FeeCollections.Vault ? (
            <Stack spacing="16px" sx={{ width: '300px' }}>
              <FeeVaultRow
                description={`Balance in ETH for account ${L1_FEE_ADDR} in L2`}
                isLoading={isLoading}
                title="L1 Fee Vault"
                value={formatEthNumber(l1FeeVault)}
              />
              <FeeVaultRow
                description={`Balance in ETH for account ${BASE_FEE_ADDR} in L2`}
                isLoading={isLoading}
                title="Base Fee Vault"
                value={formatEthNumber(baseFeeVault)}
              />
              <FeeVaultRow
                description={`Balance in ETH for account ${SEQUENCER_FEE_ADDR} in L2`}
                isLoading={isLoading}
                title="Sequencer Fee Vault"
                value={formatEthNumber(sequencerFeeVault)}
              />
            </Stack>
          ) : (
            keys.length > 0 && (
              <Stack direction="row" spacing="24px">
                <Typography variant="caption">Keys</Typography>

                <Stack spacing="9px">
                  {keys?.map(key => {
                    return (
                      <CopyableAddress
                        address={key}
                        key={key}
                        sx={{ color: theme => theme.colors.functional.text.link }}
                        truncated={false}
                      />
                    );
                  })}
                </Stack>
              </Stack>
            )
          )}
        </Box>
      </Stack>
    </Box>
  );
};

const FeeVaultRow: React.FC<IFeeVaultRow> = ({ description, isLoading, title, value }) => {
  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between">
      <TextTooltip
        infoIconSx={{ width: '15px', height: '15px' }}
        tooltipText={description}
        variant="caption"
      >
        {title}
      </TextTooltip>

      {isLoading ? (
        <Skeleton variant="text" width="35%" />
      ) : (
        <Typography variant="captionM">{value}</Typography>
      )}
    </Stack>
  );
};
