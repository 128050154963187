import { Box, Stack, SxProps, Tooltip, Typography, TypographyProps } from '@mui/material';
import React from 'react';

import IconInfo from '../icons/IconInfo';

interface ITextTooltip extends TypographyProps {
  tooltipText: string;
  infoIconSx?: SxProps;
}

export const TextTooltip: React.FC<ITextTooltip> = ({
  children,
  infoIconSx,
  tooltipText,
  ...typographyProps
}) => {
  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      <Typography {...typographyProps}>{children}</Typography>

      <Tooltip title={tooltipText}>
        <Box sx={{ height: '20px' }}>
          <IconInfo sx={infoIconSx} />
        </Box>
      </Tooltip>
    </Stack>
  );
};
