import { LoadingButton } from '@mui/lab';
import { Box, Grid, Stack } from '@mui/material';
import { IconButtonCopy } from 'pages/admin/ManageClients/IconButtonCopy';
import { FC } from 'react';
import { FormProvider, useFormContext } from 'react-hook-form';
import {
  FormNumericTextField,
  FormSelectDateField,
  FormTextFieldBase,
  Label,
} from 'shared/components/form';
import { FormSelectField, FormSelectItem } from 'shared/components/form/fields/FormSelectField';
import {
  FormGroupContainer,
  IFormGroupContainer,
} from 'shared/components/form/form-group/FormGroupContainer';
import { useBreakpoints } from 'shared/hooks/ui/useBreakpoints';
import { CreatePaymentRequest } from 'shared/types/protoc-gen/bffbillsystem';
import { zeroHash } from 'viem';

interface FormValues extends Omit<CreatePaymentRequest, 'agreementId'> {}

interface IClientPaymentForm {
  submitButtonLabelText: string;
  submitButtonLoading: boolean;
  writeMode?: boolean;
}

export const ClientPaymentForm = ({
  submitButtonLabelText,
  submitButtonLoading,
  writeMode = true,
}: IClientPaymentForm) => {
  const { sm } = useBreakpoints();
  const form = useFormContext<FormValues>();

  return (
    <FormProvider {...form}>
      <Box
        data-testid="client-payment-form"
        px={{ md: 5, sm: 3, xs: 3 }}
        py={3}
        sx={
          !writeMode
            ? {
                '.MuiInputBase-root.Mui-disabled': {
                  background: '#fff',
                  borderRadius: 0,
                },
              }
            : undefined
        }
      >
        <Grid columnSpacing={3} container rowSpacing={5}>
          <Grid item md={6} sm={12} xs={12}>
            <GeneralInfoFormGroup writeMode={writeMode} />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <PaymentDetailsFormGroup writeMode={writeMode} />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Stack alignItems="flex-end">
              <LoadingButton
                data-testid="submit-button"
                disabled={!writeMode}
                fullWidth={sm}
                id="submit-button"
                loading={submitButtonLoading}
                type="submit"
                variant="contained"
              >
                {submitButtonLabelText}
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  );
};

interface IGeneralInfoFormGroup extends Omit<IFormGroupContainer, 'title'> {
  writeMode: boolean;
  includeBindingChains?: boolean;
}

const GeneralInfoFormGroup: FC<IGeneralInfoFormGroup> = ({ writeMode, ...props }) => {
  const form = useFormContext<FormValues>();

  return (
    <FormProvider {...form}>
      <FormGroupContainer
        data-testid="client-payment-form-general-information"
        title="General Information"
        {...props}
      >
        <Box>
          <Grid columnSpacing={3} container rowSpacing={3}>
            <Grid item md={6} sm={12} xs={12}>
              <Label data-testid="type_label" label="Type">
                <FormSelectField<FormSelectItem>
                  data-testid="type_field"
                  disabled={!writeMode}
                  fieldConfig={{ name: 'type', label: 'Type' }}
                  id="type_field"
                  items={[
                    {
                      label: 'Service fee',
                      value: 'Service fee',
                    },
                    { label: 'Withdraw fee', value: 'Withdraw fee' },
                    { label: 'Other fee', value: 'Other fee' },
                  ]}
                />
              </Label>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Label data-testid="subType_label" label="Sub Type">
                <FormTextFieldBase<FormValues>
                  InputProps={
                    !writeMode
                      ? {
                          endAdornment: <IconButtonCopy<FormValues> field="subType" />,
                        }
                      : undefined
                  }
                  data-testid="subType_field"
                  disabled={!writeMode}
                  fieldConfig={{ name: 'subType', placeholder: 'Enter sub type' }}
                  helperTextShrink
                  id="subType_field"
                />
              </Label>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Label data-testid="status_label" label="Status">
                <FormSelectField<FormSelectItem>
                  data-testid="status_field"
                  disabled
                  fieldConfig={{ name: 'status', placeholder: 'Select status' }}
                  id="status_field"
                  items={[
                    { label: 'Awaiting', value: 'Awaiting' },
                    {
                      label: 'Paid',
                      value: 'Paid',
                    },
                    {
                      label: 'Overdue',
                      value: 'Overdue',
                    },
                    {
                      label: 'Paid After Overdue',
                      value: 'PaidAfterOverdue',
                    },
                  ]}
                />
              </Label>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Label data-testid="amount_label" label="Amount">
                <FormNumericTextField<FormValues>
                  data-testid="amount_field"
                  disabled={!writeMode}
                  fieldConfig={{ name: 'amount', label: 'Amount', placeholder: 'Enter amount' }}
                  helperTextShrink
                  hideLabel
                  id="amount_field"
                />
              </Label>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Label data-testid="currency_label" label="Currency">
                <FormSelectField<FormSelectItem>
                  data-testid="currency_field"
                  disabled={!writeMode}
                  fieldConfig={{
                    name: 'currency',
                    label: 'Currency',
                    placeholder: 'Select currency',
                  }}
                  id="currency_field"
                  items={[
                    {
                      label: 'USDT',
                      value: 'USDT',
                    },
                    {
                      label: 'ETH',
                      value: 'ETH',
                    },
                    {
                      label: 'BTC',
                      value: 'BTC',
                    },
                    {
                      label: 'USDC',
                      value: 'USDC',
                    },
                  ]}
                />
              </Label>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Label data-testid="paymentPeriodFrom_label" label="Payment Period From">
                <FormSelectDateField
                  InputProps={
                    !writeMode
                      ? {
                          endAdornment: <IconButtonCopy<FormValues> field="paymentPeriodFrom" />,
                        }
                      : undefined
                  }
                  data-testid="paymentPeriodFrom_field"
                  disabled={!writeMode}
                  fieldConfig={{
                    name: 'paymentPeriodFrom',
                    label: 'Payment Period From',
                  }}
                  format="YYYY/MM/DD"
                  id="paymentPeriodFrom_field"
                  type="date"
                />
              </Label>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Label data-testid="paymentPeriodTo_label" label="Payment Period To">
                <FormSelectDateField
                  InputProps={
                    !writeMode
                      ? {
                          endAdornment: <IconButtonCopy<FormValues> field="paymentPeriodTo" />,
                        }
                      : undefined
                  }
                  data-testid="paymentPeriodTo_field"
                  disabled={!writeMode}
                  fieldConfig={{ name: 'paymentPeriodTo', label: 'Payment Period To' }}
                  format="YYYY/MM/DD"
                  id="paymentPeriodTo_field"
                  type="date"
                />
              </Label>
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Label data-testid="nextPaymentDate_label" label="Next Payment Date">
                <FormSelectDateField
                  InputProps={
                    !writeMode
                      ? {
                          endAdornment: <IconButtonCopy<FormValues> field="nextPaymentDate" />,
                        }
                      : undefined
                  }
                  data-testid="nextPaymentDate_field"
                  disabled
                  fieldConfig={{ name: 'nextPaymentDate' }}
                  format="YYYY/MM/DD"
                  id="nextPaymentDate_field"
                  type="datetime-local"
                />
              </Label>
            </Grid>
          </Grid>
        </Box>
      </FormGroupContainer>
    </FormProvider>
  );
};

interface IPaymentDetailsFormGroup extends Omit<IFormGroupContainer, 'title'> {
  writeMode: boolean;
}

const PaymentDetailsFormGroup: FC<IPaymentDetailsFormGroup> = ({ writeMode, ...props }) => {
  const form = useFormContext<FormValues>();

  return (
    <FormProvider {...form}>
      <FormGroupContainer
        data-testid="client-payment-form-payment-details"
        title="Payment Details"
        {...props}
      >
        <Stack spacing={2}>
          <Stack alignItems="start" direction="row" spacing={3}>
            <Label data-testid="invoiceTime_label" label="Invoice Date">
              <FormSelectDateField
                InputProps={
                  !writeMode
                    ? {
                        endAdornment: <IconButtonCopy<FormValues> field="invoiceTime" />,
                      }
                    : undefined
                }
                data-testid="invoiceTime_field"
                disabled={!writeMode}
                fieldConfig={{ name: 'invoiceTime' }}
                format="YYYY/MM/DD"
                helperText={`Kindly make payment before {date} to avoid any service disruption or late fees`} // TODO: replace with specific date
                id="invoiceTime_field"
                type="datetime-local"
              />
            </Label>
          </Stack>

          <Stack alignItems="start" direction="row" spacing={3}>
            <Label data-testid="paymentTime_label" label="Payment Date">
              <FormSelectDateField
                InputProps={
                  !writeMode
                    ? {
                        endAdornment: <IconButtonCopy<FormValues> field="paymentTime" />,
                      }
                    : undefined
                }
                data-testid="paymentTime_field"
                disabled={!writeMode}
                fieldConfig={{ name: 'paymentTime' }}
                format="YYYY/MM/DD"
                id="paymentTime_field"
                type="datetime-local"
              />
            </Label>
          </Stack>

          <Label data-testid="paymentTxhash_label" label="Payment Transaction Hash">
            <FormTextFieldBase<FormValues>
              InputProps={
                !writeMode
                  ? {
                      endAdornment: <IconButtonCopy<FormValues> field="paymentTxhash" />,
                    }
                  : undefined
              }
              data-testid="paymentTxhash_field"
              disabled={!writeMode}
              fieldConfig={{ name: 'paymentTxhash', placeholder: zeroHash }}
              id="paymentTxhash_field"
            />
          </Label>
        </Stack>
      </FormGroupContainer>
    </FormProvider>
  );
};
