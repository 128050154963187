import ViewSidebarIcon from '@mui/icons-material/ViewSidebar';
import { Drawer, IconButton, Stack, useTheme } from '@mui/material';
import { ADMIN_PATHS } from 'pages/admin/routes';
import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { FixedSideNavigationBar } from 'shared/components/Layout/FixedSideNavigationBar';
import { PageBanner, PageBannerVariant } from 'shared/components/Layout/PageBanner';
import { useDisclosure } from 'shared/hooks/ui';
import { useBreakpoints } from 'shared/hooks/ui/useBreakpoints';
import { NavigationItem } from 'shared/types/navigation';

export const AdminLayout = () => {
  const theme = useTheme();
  const { sm } = useBreakpoints();
  const {
    onClose: onCloseSideNavigationDrawer,
    onOpen: onOpenSideNavigationDrawer,
    open: isOpenSideNavigationDrawer,
  } = useDisclosure();

  const sideNavigationItems: NavigationItem[] = useMemo(
    () => [
      {
        id: 'admin',
        label: 'Overview',
        items: [
          {
            label: 'Promo Codes',
            to: ADMIN_PATHS.MANAGE_PROMO_CODES,
          },
          {
            label: 'Agent Requests',
            to: ADMIN_PATHS.MANAGE_AGENT_REQUESTS,
          },
          {
            label: 'Finetune Sources',
            to: ADMIN_PATHS.MANAGE_FINETUNE_SOURCES,
          },
          {
            label: 'RaaS Bills',
            to: ADMIN_PATHS.MANAGE_CLIENTS,
          },
        ],
      },
    ],
    [],
  );

  return (
    <>
      <Drawer onClose={onCloseSideNavigationDrawer} open={isOpenSideNavigationDrawer}>
        <FixedSideNavigationBar
          primaryColor={theme.colors.schema.altLayerPrimary}
          sideNavigationItems={sideNavigationItems}
          sx={{
            position: 'sticky',
            top: '0',
            alignSelf: 'flex-start',
            zIndex: 100,
            overflow: 'auto',
            maxHeight: '100vh',
          }}
          width="100%"
        />
      </Drawer>

      <Stack>
        <PageBanner
          startAdornment={
            sm && (
              <IconButton
                onClick={onOpenSideNavigationDrawer}
                sx={{
                  border: theme => `1px solid ${theme.colors.functional.text.primary}`,
                  borderRadius: 0,
                }}
              >
                <ViewSidebarIcon />
              </IconButton>
            )
          }
          title={'Admin Dashboard'}
          variant={PageBannerVariant.ADMIN}
        />

        <Stack
          flexDirection="row"
          sx={{ position: 'relative', mb: '164px', background: '#fafafa' }}
        >
          {!sm && (
            <FixedSideNavigationBar
              primaryColor={theme.colors.schema.altLayerPrimary}
              sideNavigationItems={sideNavigationItems}
              sx={{
                position: 'sticky',
                top: '0',
                alignSelf: 'flex-start',
                zIndex: 100,
                overflow: 'auto',
                maxHeight: '100vh',
              }}
              width={{ md: 300, lg: 400, xl: 500 }}
            />
          )}

          <Stack
            sx={{
              alignItems: 'center',
              flexDirection: 'column',
              width: '100%',
              overflowY: 'auto',
            }}
          >
            <Outlet />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
